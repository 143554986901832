import { Dialog, DialogContent } from '@mui/material';
import InvestorUpdate from '../../investor/investor-update/InvestorUpdate';
import CloseIcon from '@mui/icons-material/Close';

const InvestorDetails = (props) => {
    let {investorId, isInvestorDetailsDialogOpen, closeInvestorDetailsDialog} = props;

    return (
        <Dialog open={isInvestorDetailsDialogOpen} onClose={closeInvestorDetailsDialog} maxWidth={'lg'} >
            <DialogContent sx={{width:'90vw', maxWidth:'lg'}} >
                <span onClick={closeInvestorDetailsDialog} ><CloseIcon sx={{float:'right', cursor:'pointer'}}/></span>
                <InvestorUpdate investorId={investorId} />
            </DialogContent>
        </Dialog>
    )
}

export default InvestorDetails