import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DesktopLayout from './layouts/DesktopLayout';
import LoginLayout from './layouts/LoginLayout';
import RequireAuth from './components/RequireAuth';
import { Box, Grid } from '@mui/material';
import useWindowDimensions from './components/WindowDimension';
import Login from './pages/login/Login';
//import Home from './pages/home/Home';
import Home from './pages/home/home';
import LeadSearch from './pages/lead/lead-search/LeadSearch';
import LeadUpdate from './pages/lead/lead-update/LeadUpdate';
import Empty from './components/Empty';
import UserSearch from './pages/user/UserSearch';

const Upload = lazy(() => import('./pages/upload/Upload'));
const Admin = lazy(() => import('./pages/admin/Admin'));
const HotLeads = lazy(() => import('./pages/lead/hot-leads/HotLeads'));
const InvestorUpdate = lazy(() => import('./pages/investor/investor-update/InvestorUpdate'));
const InvestorImport = lazy(() => import('./pages/investor/investor-update/InvestorImport'))
const InvestorSearch = lazy(() => import('./pages/investor/investor-search/InvestorSearch'));

const ProspectUpdate = lazy(() => import('./pages/prospect/prospect-uadate/ProspectUpdate'));
const ProspectSearch = lazy(() => import('./pages/prospect/prospect-search/ProspectSearch'));
const ProspectCreate = lazy(() => import('./pages/prospect/prospect-create/ProspectCreate'));

const EmailSettings = lazy(() => import('./pages/email/email-settings/EmailSettings'));
const WhatsappSettings = lazy(() => import('./pages/whatsapp/whatsapp-settings/WhatsappSettings'))
const RecipientListSearch = lazy(() => import('./pages/email/manage-recipient-lists/RecipientListSearch'));
const CreateMailing = lazy(() => import('./pages/email/create-mailing/CreateMailing'));


function App() {
    const { height } = useWindowDimensions();

    return (
        <Router>
            <Suspense fallback={<Grid height={height} container justifyContent='center' alignItems='center'><Grid item><Box>Loading</Box></Grid></Grid>}>
                <Routes>

                    {/* Login layout */}
                    <Route path='/login' element={<LoginLayout />}>
                        <Route index element={<Login />} />
                    </Route>

                    {/* Desktop layout routes */}
                    <Route element={<RequireAuth />}>
                        <Route path='/' element={<DesktopLayout />}>
                            <Route index element={<Login />} />
                            <Route path='home' element={<Home />} />
                            <Route path='lead-search' element={<LeadSearch />} />
                            <Route path='lead-update' element={<LeadUpdate />} />
                            <Route path='hot-leads' element={<HotLeads />} />
                            <Route path='prospect-search' element={<ProspectSearch />} />
                            <Route path='prospect-update' element={<ProspectUpdate />} />
                            <Route path='investor-search' element={<InvestorSearch />} />
                            <Route path='prospect-create' element={<ProspectCreate />} />
                            <Route path='investor-update' element={<InvestorUpdate />} />
                            <Route path='investor-import' element={<InvestorImport />} />
                            <Route path='user-search' element={<UserSearch />} />
                            <Route path='upload' element={<Upload />} />           
                            <Route path='admin' element={<Admin />} />
                            <Route path='empty' element={<Empty />} />
                            <Route path='whatsapp-settings' element={<WhatsappSettings />} />
                            <Route path='email-settings' element={<EmailSettings />} />
                            <Route path='manage-recipient-lists' element={<RecipientListSearch />} />
                            <Route path='create-mailing' element={<CreateMailing />} />
                        </Route>
                    </Route>

                </Routes>
            </Suspense>
        </Router >
    );
}

export default App;