
import { createTheme } from '@mui/material';
import { grey, } from '@mui/material/colors';

const desktopTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#1F8BDB',
            contrastText: '#fff'
        },
        secondary: {
            main: '#BF9F78',
            contrastText: '#fff'
        },
        info: {
            main: '#1F8BDB',
            contrastText: '#fff'
        },
        warning: {
            main: '#F2BD1D',
            contrastText: '#fff'
        },
        success: {
            main: '#339933',
            contrastText: '#fff'
        },
        background: {
            paper: grey['200'],
            default: '#fff',
            dark: '#000',
        },
    },
    typography: {
        fontFamily: 'Poppins',
        fontSize: 12,
    }
});

export default desktopTheme;
