import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PageHeader from '../../components/PageHeader';
import UserForm from './UserSearchForm';
import UserAlert from '../../components/UserAlert';
import { getUsers } from '../../services/UserService';
import UserSearchTable from './UserSearchTable';
import { USER_ROLES, USER_STATUS } from '../../constants/AppConsts';
import UserDetailsForm from './UserDetailsForm';

const icon = <PersonIcon />

const UserSearch = (props) => {
    let { tableHeight } = props;
    if (!tableHeight) tableHeight = '500px';

    const userAlert = useRef(null);
    const [errors, setErrors] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const [user, setUser] = useState(null)
    const [isUserDialogOpen, setIsUserDialogOpen] = useState(false)
    const [searchCount, setSearchCount] = useState(0)
    const initialValues = {
        did: '',
        name: '',
        mobilePhone: '',
        email: '',
        userRole: null,
        userStatus: null
    }
    const [values, setValues] = useState(initialValues)

    useEffect(() => {
        function init(){
            setValues({
                ...values,
                userStatus: USER_STATUS.ACTIVE.value
            })
            searchUsers()
        }
        init()
    }, [ ])
    
    const handleInputChanage = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        })
    }

    const handleMultiSelectChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: typeof value === 'string' ? value.split(',') : value,
        });
    };

    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 100
    })

    const mapUsersToTableColumn = (users) => {
        return users?.map(user => {
            const userObj = Object.assign({}, user);
            console.log(user.userStatus);
            return ({
                id: user.did,
                did: user.did,
                email: user.email,
                name: user.name,
                mobilePhone: user.mobilePhone,
                userRole: USER_ROLES[user.userRole].name,
                userStatus: USER_STATUS[user.userStatus].name,
                userObj: userObj
            });
        })
    }

    const searchUsers = async () => {
        setLoadData(true);
        userAlert.current.clearAll();

        let temp = {};
        temp.mobilePhone = (values.mobilePhone === '' || (/^[\+]?[00]?[0-9]{2}[\-]?[0-9]{8,12}$/).test(values.mobilePhone)) ? '' : 'Phone noumber has to be valid (minimum 10 digits)'
        temp.email = (values.email === '' || (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(values.email)) ? '' : 'A valied email address is required'
        setErrors({ ...temp })

        if (Object.values(temp).every(x => x === "")) {
            setPageState(old => ({ ...old, isLoading: true }));

            let response = await getUsers({
                ...values,
                pn: pageState.page,
                ipp: pageState.pageSize,

            });
            setLoadData(false);
            setPageState(old => ({ ...old, isLoading: false, data: mapUsersToTableColumn(response.content), total: response.totalElements }));
        }
    }

    useEffect(() => {
        if (loadData) searchUsers();
    }, [loadData, pageState.pageSize, pageState.page, searchCount])

    const clearForm = () => {
        setValues(initialValues);
        let temp = {};
        setErrors({ ...temp })
        setLoadData(false);
    }

    const initUserDetailForm = () => {
        // search form values
        // setValues({...initialValues }) 
        
        // user dialog values
        setUser({...initialValues, userStatus: USER_STATUS.ACTIVE.value})
        
        let temp = {};
        setErrors({ ...temp })
        setLoadData(false);
    }

    const openUserDialog = (userObj) => {
        setLoadData(false);
        if(userObj?.did==null) {
            initUserDetailForm();
        } else{
            setUser(userObj)
        }
        setIsUserDialogOpen(true)
    }

    const closeUserDialog = () => {
        setSearchCount(cnt => ++cnt)
        setLoadData(true)
        setIsUserDialogOpen(false)
    }

    return (
        <section>
            <PageHeader title='Search Users' icon={icon} />
            <UserForm userAlert={<UserAlert ref={userAlert} />} 
                values={values} 
                errors={errors} 
                handleInputChanage={handleInputChanage} 
                handleMultiSelectChange={handleMultiSelectChange}
                searchUsers={searchUsers}
                clearForm={clearForm} 
                openUserDialog={openUserDialog}
            />
            <Box mt={1} />
            <PageHeader title='Search Users - Results' icon={icon}  />
            <UserSearchTable 
                pageState={pageState} 
                setPageState={setPageState} 
                openUserDialog={openUserDialog} 
                tableHeight={tableHeight} 
                />
            <UserDetailsForm 
                isUserDialogOpen={isUserDialogOpen}
                closeUserDialog={closeUserDialog} 
                user={user}
                />
        </section>
    )
}

export default UserSearch