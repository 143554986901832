import { Alert } from '@mui/material';
import React, { Component } from 'react'

export default class UserAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoMessage: '',
            warnMessage: '',
            errorMessage: '',
        };
    }

    setInfo = (message) => {
        this.clearAll();
        this.setState({ infoMessage: message })
    }

    setWarn = (message) => {
        this.clearAll();
        this.setState({ warnMessage: message })
    }

    setError = (message) => {
        this.clearAll();
        this.setState({ errorMessage: message })
    }

    clearAll = () => {
        this.setState(
            { infoMessage: '', warnMessage: '', errorMessage: '' }
        )
    }


    render() {

        return (
            <>
                {this.state.infoMessage && <Alert color='success'>{this.state.infoMessage}</Alert>}
                {this.state.warnMessage && <Alert color='warning'>{this.state.warnMessage}</Alert>}
                {this.state.errorMessage && <Alert color='error'>{this.state.errorMessage}</Alert>}
            </>
        )
    }
}
