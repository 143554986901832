import moment from 'moment';
import { apiClientPrivate } from './axios';
import { gGetError } from '../CommonUtils'

export async function getLeads(leadObj) {
    try {
        let response = await apiClientPrivate.post(`/rest/lead/auf/list?pn=${leadObj.pn}&ipp=${leadObj.ipp}`,
            getPayload(leadObj)
        );
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function getLeadsExports(leadObj, mode = "xlsx") {
    let postLink = mode === "csv" ? `/rest/lead/auf/list/exportCSV` : `/rest/lead/auf/list/export`;
        
    try {
        let response = await apiClientPrivate.post(postLink,
            getPayload(leadObj)
        );
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

/*export async function getLeadsCSV(leadObj) {
    try {
        let response = await apiClientPrivate.post(`/rest/lead/auf/list/exportCSV`,
        getPayload(leadObj)
        );
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}*/

const getPayload = (leadObj) => {
    return {
        searchName: leadObj.searchName,
        nextAction: 
            leadObj.nextActions.map(nextA => ({code: nextA})),
        nextActionDateFrom: leadObj.nextActionDateFrom ? moment(leadObj.nextActionDateFrom, "YYYY-MM-DD").format("DD/MM/YYYY") : '',
        nextActionDateTo: leadObj.nextActionDateTo ? moment(leadObj.nextActionDateTo, "YYYY-MM-DD").format("DD/MM/YYYY") : '',
        priceDesired: leadObj.priceDesired,
        sourceAdId: leadObj.sourceAdId,
        propertyAddress: {
            houseNumber: leadObj.houseNumber,
            street: leadObj.street,
            city: leadObj.city,
            postalCode: leadObj.postalCode,
        },
        propertyProvince: {
            code: leadObj.provinceCode,
        },
        id: leadObj.id,
        phoneNumber: leadObj.phoneNumber,
        email: leadObj.email,
        keyword: leadObj.keyword,
        leadPropertyTypes: [
            {
                propertyType: { code: null }
            }
        ],
    }
}

export async function getLeadById(leadId) {
    try {
        let response = await apiClientPrivate.get(`/rest/lead/auf/${leadId}`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function saveLead(leadObj) {
    try {
        let response = await apiClientPrivate.post(`/rest/lead/auf/create`,
            {
                id: leadObj.id,
                searchName: leadObj.searchName,
                propertyAddress: {
                    street: leadObj.street,
                    houseNumber: leadObj.houseNumber,
                    houseNumberSuffix: leadObj.houseNumberSuffix,
                    city: leadObj.city,
                    postalCode: leadObj.postalCode,
                    countryCode: "NL",
                },
                leadPropertyTypes: [{
                    propertyType: {
                        code: leadObj.propertyTypeCode,
                    }
                }],
                propertyProvince: {
                    code: leadObj.provinceCode,
                },
                source: {
                    code: leadObj.sourceCode,
                },
                salesStart: {
                    code: leadObj.salesStartCode,
                },
                priceDesired: leadObj.priceDesired,
                priceWLFrom: leadObj.priceWLFrom,
                priceWLTo: leadObj.priceWLTo,
                wozPropertyValue: leadObj.wozPropertyValue,
                floorAreaSqMeters: leadObj.floorAreaSqMeters,
                phoneNumber: leadObj.phoneNumber,
                email: leadObj.email,
                sellerRemark: leadObj.sellerRemark,
                rdVastgoedRemark: leadObj.rdVastgoedRemark,
                primaryPartyDetail: {
                    isPrimaryParty: leadObj.isPrimaryParty,
                    partyType: "INDIVIDUAL",
                    companyName: "",
                    firstName: leadObj.firstName,
                    lastName: leadObj.lastName,
                    phoneNumber: leadObj.phoneNumber,
                    email: leadObj.email,
                    remark: leadObj.remark,
                    idType: leadObj.idType,
                    idNumber: leadObj.idNumber,
                    address: {
                        street: leadObj.street,
                        houseNumber: leadObj.houseNumber,
                        houseNumberSuffix: leadObj.houseNumberSuffix,
                        city: leadObj.city,
                        postalCode: leadObj.postalCode,
                        countryCode: "NL",
                    }
                },
                sourceAdId: leadObj.sourceAdId,
                floorAreaSquareMeters: leadObj.floorAreaSqMeters,
                isOwnProperty: leadObj.isOwnProperty,
            }
        )
        return response.data

    } catch (err) {
        return gGetError(err)
    }
}

export async function getColdLeadsCount() {
    try {
        let response = await apiClientPrivate.get(`/rest/lead/auf/getColdLeadsCount`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function getHotLeadsCount() {
    try {
        let response = await apiClientPrivate.get(`/rest/lead/auf/getHotLeadsCount`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function getCreateProspectCount() {
    try {
        let response = await apiClientPrivate.get(`/rest/lead/auf/getCreateProspectCount`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function getAllLeadsCount() {
    try {
        let response = await apiClientPrivate.get(`/rest/lead/auf/getAllLeadsCount`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}