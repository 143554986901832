export const BASE_URL = process.env.REACT_APP_BASE_URL
export const HTTP_TIMEOUT = 60000

export const COUNTRY_CODE_NL = "NL"

export const HTTP_STATUS = {
    OK: 200,
    INTERNAL_SERVER_ERROR: 500,
}

export const ACTION_CLASS = {
    LEAD: "LEAD",
    PROSPECT: "PROSPECT",
}

export const ACTION_CATEGORY = {
    LEAD_INITIAL: "LEAD_INITIAL",
    LEAD_READY_FOR_SALE: "LEAD_READY_FOR_SALE",
    LEAD_AWAIT_MAILING_RESULT: "LEAD_AWAIT_MAILING_RESULT",
    LEAD_PROSPECT: "LEAD_PROSPECT",
    LEAD_CLOSED: "LEAD_CLOSED",
    PROSPECT_INITIAL: "PROSPECT_INITIAL",
    PROSPECT_OTHER: "PROSPECT_OTHER",
}

export const SOURCE_CLASS = {
    LEAD: "LEAD",
    INVESTOR: "INVESTOR",
}

export const ATTACHMENT_CLASS = {
    LEAD: "LEAD",
    INVESTOR: "INVESTOR",
}

export const INVESTOR_STATUS = {
    INITIAL: "100",
    ACTIVE: "200",
    INACTIVE: "900",
}

export const USER_ROLES = {
    USER: { value: "USER", name: "User" },
    ADMINISTRATOR: { value: "ADMINISTRATOR", name: "Admin" },
    SUPER_ADMIN: { value: "SUPER_ADMIN", name: "Super Admin" },
}

export const USER_STATUS = {
    ACTIVE: { value: "ACTIVE", name: "Active" },
    INACTIVE: { value: "INACTIVE", name: "Inactive" },
}

export const DUTCH_LOCALE = "en-NL"
