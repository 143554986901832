import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ConfirmationDialog = (props) => {
    let { isOpen, title, question, confirmButtonText, cancelButtonText, handleConfirm, handleCancel, isValidPhoneNumber } = props;

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleCancel}
                aria-labelledby={title}
            >
                <DialogTitle style={{ cursor: 'move' }} id={title}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={isValidPhoneNumber ? {} : {color:'#ff0000'}}>
                        {question}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCancel} variant='outlined'>
                        {cancelButtonText}
                    </Button>
                    <Button onClick={handleConfirm} variant='contained'>{confirmButtonText}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ConfirmationDialog