import { gGetError } from '../CommonUtils';
import { ACTION_CLASS } from '../constants/AppConsts';
import { apiClientPrivate } from './axios';


export async function getActionsByClassAndCategory(actionClass, actionCategory) {
    try {
        let response;
        // in this method category is optional
        response = await apiClientPrivate.get(`/rest/action/auf/by-category?actionClass=${actionClass}&actionCategory=${actionCategory}`);
        return response.data
    } catch (err) {
        return gGetError(err)
    }
}

export async function getActionSeriesByLeadId(objectId) {
    try {
        let response = await apiClientPrivate.get(`/rest/action/auf/by-lead?leadID=${objectId}`);
        return response.data
    } catch (err) {
        return gGetError(err)
    }
}

export async function getActionSeriesByProspectId(objectId) {
    try {
        let response = await apiClientPrivate.get(`/rest/action/auf/by-prospect?prospectId=${objectId}`);
        return response.data
    } catch (err) {
        return gGetError(err)
    }
}

export async function saveNextAction(actionObj) {
    try {
        let url = (actionObj.ownerClass === ACTION_CLASS.LEAD) ? `/rest/lead/auf/${actionObj.ownerId}/next-action` : `/rest/prospect/auf/${actionObj.ownerId}/next-action`
        let response = await apiClientPrivate.post(url,
            {
                "date": actionObj.actionDate,
                "remarks": actionObj.remarks,
                "action": {
                    "class": actionObj.actionClass,
                    "code": actionObj.actionCode
                }
            }
        );
        return response.data
    } catch (err) {
        return gGetError(err)
    }
}

export async function deleteNextAction(seriesDid) {
    try {
        let response = await apiClientPrivate.delete(`/rest/action/series/auf?did=${seriesDid}`);
        return response.data
    } catch (err) {
        return gGetError(err)
    }
}

export async function isAClosedAction(actionClass, actionCode) {
    try {
        let response = await apiClientPrivate.get(`/rest/action/auf/isAClosedAction?actionClass=${actionClass}&actionCode=${actionCode}`);
        return response.data
    } catch (err) {
        return gGetError(err)
    }
}
