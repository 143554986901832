import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import moment from 'moment';
import React from 'react';
import FormInput from './FormInput';
import FormSelect from './FormSelect';

// use mui styles
const StyledTable = styled('table')(({ theme }) => ({
    width: '100%',
    '& th': {
        paddingLeft: 5,
        backgroundColor: theme.palette.grey[300],
        fontWeight: 400,
    },
    '& td': {
        paddingLeft: 5,
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: theme.palette.background.default
    }
}));

const DeleteIcon = styled(DeleteForeverOutlinedIcon)(({ theme }) => ({
    color: theme.palette.error.main,
}));


const ActionSeriesView = (props) => {
    let { actions, actionSeries, deleteAction, values, handleInputChanage, saveNextAction, userAlert } = props;

    return (
        <>
            {userAlert}

            {/* last action */}
            <StyledTable container color={'red'} id="action-series-table">
                <thead>
                    <tr style={{ textAlign: 'left' }}>
                        <th style={{ width: '30%' }}><Typography>Next Action</Typography></th>
                        <th style={{ width: '20%' }}><Typography>Next Action Date</Typography></th>
                        <th style={{ width: '*' }}><Typography>Next Action Comment</Typography></th>
                        <th style={{ width: '50px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><Typography>{actionSeries[0] ? `${actionSeries[0]?.action.code} - ${actionSeries[0]?.action.description}` : ''}</Typography></td>
                        <td><Typography>{actionSeries[0]?.date}</Typography></td>
                        <td><Typography>{actionSeries[0]?.remarks}</Typography> </td>
                        {/* <td style={{ textAlign: 'center' }}><Tooltip title='Delete action'><DeleteIcon /></Tooltip></td> */}
                        <td style={{ textAlign: 'center' }}><Tooltip title='Delete action'><span onClick={() => deleteAction(actionSeries[0])}><DeleteIcon sx={{ cursor: 'pointer' }} /></span></Tooltip></td>
                    </tr>

                </tbody>
            </StyledTable>

            <Box sx={{ height: 10 }}>&nbsp;</Box>

            {/* add new next action */}
            <StyledTable>
                <thead>
                    <tr style={{ textAlign: 'left' }}>
                        <th style={{ width: '30%' }}><Typography>New Action</Typography></th>
                        <th style={{ width: '20%' }}><Typography>New Action Date</Typography></th>
                        <th style={{ width: '*' }}><Typography>Action Comment</Typography></th>
                        <th style={{ width: '50px' }}></th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <FormSelect
                                name="actionCode"
                                label="Select Next Action"
                                value={values.actionCode || ''}
                                onChange={handleInputChanage}
                                items={actions}

                            />
                        </td>
                        <td>
                            <FormInput label='' value={values.actionDate || ''} name='actionDate' type='date' onChange={handleInputChanage} />
                        </td>
                        <td>
                            <FormInput label='' onChange={handleInputChanage} name='remarks' value={values.remarks || ''} />
                        </td>
                        <td style={{ textAlign: 'center' }}>
                            <Tooltip title='Save'><span onClick={saveNextAction}><SaveOutlinedIcon color='primary' sx={{ cursor: 'pointer' }} /></span></Tooltip>
                        </td>
                    </tr>
                </tbody>
            </StyledTable>

            <Box sx={{ height: 10 }}>&nbsp;</Box>

            {/* History records goes in here */}
            <StyledTable>
                <thead>
                    <tr style={{ textAlign: 'left' }}>
                        <th style={{ width: '30%' }}><Typography>Action History</Typography></th>
                        <th style={{ width: '20%' }}><Typography>Historical Action Date</Typography></th>
                        <th style={{ width: '*' }}><Typography>Action Comment</Typography></th>
                        <th style={{ width: '50px' }}></th>
                    </tr>
                </thead>

                <tbody>
                    {actionSeries && actionSeries.map(action => (
                        <tr key={action.did}>
                            <td><Typography>{`${action.action.code} - ${action.action.description}`}</Typography></td>
                            <td><Typography>{action.date}</Typography></td>
                            <td><Typography>{action.remarks}</Typography></td>
                            <td style={{ textAlign: 'center' }}><Tooltip title='Delete action'><span onClick={() => deleteAction(action)}><DeleteIcon sx={{ cursor: 'pointer' }} /></span></Tooltip></td>
                        </tr>
                    ))}
                </tbody>

            </StyledTable>
        </>
    );

}

export default ActionSeriesView;
