import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const FormMultiSelect = (props) => {
    const items = props.items || [];

    return (
        <div>
            <FormControl fullWidth size='small' sx={{ backgroundColor: 'white' }}>
                <InputLabel id="demo-multiple-checkbox-label">{props.label}</InputLabel>
                <Select
                    color='secondary'
                    labelId={props.labelId}
                    name={props.name}
                    multiple
                    value={props.value}
                    onChange={props.onChange}
                    input={<OutlinedInput label={props.label} />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {items.map((item) => {
                        return (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox checked={props.value?.indexOf(item.id) > -1} />
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        )
                    }
                    )}
                </Select>
            </FormControl>
        </div>
    );
}

export default FormMultiSelect