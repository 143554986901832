import React from 'react'
import { Button, Grid } from '@mui/material'
import { Box } from '@mui/system'
import FormInput from '../../../components/FormInput'
import FormSelect from '../../../components/FormSelect'
import FormMultiSelect from '../../../components/FormMultiSelect'
import { gIsSuperAdminUser } from '../../../CommonUtils'

// Inline styles
const styles = {
    gridItemPadding: {
        px: 0.1,
        py: 0.5
    }
};

const LeadSearchForm = (props) => {
    let { userAlert, values, errors, actions, provinces, handleInputChanage, handleMultiSelectChange, clearLeadForm, openDetailsModal, searchLeads, exportLeads, exportLeadsCSV} = props;

    return (
        <>
            {userAlert}

            <form autoComplete='off' noValidate id='frmSearchLead'>
                <Box mt={0.0} bgcolor={'background.paper'}>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Search Name' value={values.searchName} name='searchName' error={errors.searchName} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    {/* <FormInput onChange={handleInputChanage} label='Next Action' value={values.nextAction} name="nextAction" error={errors.nextAction} /> */}
                                    <FormMultiSelect
                                        name="nextActions"
                                        label="Next Action(s)"
                                        value={values.nextActions || []}
                                        onChange={handleMultiSelectChange}
                                        items={actions}
                                    />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Next Action Date From' value={values.nextActionDateFrom} name='nextActionDateFrom' error={errors.nextActionDateFrom} type='date' shrink='true'/>
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Next Action Date To' value={values.nextActionDateTo} name='nextActionDateTo' error={errors.nextActionDateTo} type='date' shrink='true' />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Desired Price' value={values.priceDesired} name='priceDesired' error={errors.priceDesired} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Street' name="street" value={values.street} error={errors.street} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='House No.' name="houseNumber" value={values.houseNumber} error={errors.houseNumber} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Postal Code' name="postalCode" value={values.postalCode} error={errors.postalCode} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='City' name="city" value={values.city} error={errors.city} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormSelect
                                        name="provinceCode"
                                        label="Province"
                                        value={values.provinceCode || ''}
                                        onChange={handleInputChanage}
                                        items={provinces}
                                        error={errors.provinceCode}

                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Lead Id' value={values.id} name="id" error={errors.id} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Phone No.' value={values.phoneNumber} name="phoneNumber" error={errors.phoneNumber} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Email' value={values.email} name="email" error={errors.email} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Source Ad Id' name="sourceAdId" value={values.sourceAdId} error={errors.sourceAdId} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Remarks keyword' name="keyword" value={values.keyword} error={errors.keyword} />
                                </Grid>
                                {/* <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Company Name' value={values.companyName} name="companyName" error={errors.companyName} disabled />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='FirstName' value={values.firstName} name="firstName" error={errors.firstName} disabled />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Last Name' value={values.lastName} name="lastName" error={errors.lastName} disabled />
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Box pb={1} mx={1} width={'100%'}>
                            <Button size='small' type='button' variant="contained" sx={{ float: 'right', marginLeft: 1 }} onClick={openDetailsModal}>ADD</Button>
                            <Button size='small' type='button' variant="outlined" sx={{ float: 'right', marginLeft: 1 }} onClick={clearLeadForm}>CLEAR</Button>
                            <Button size='small' type='button' variant="contained" sx={{ float: 'right', marginLeft: 1 }} onClick={searchLeads}>SEARCH</Button>
                            {gIsSuperAdminUser() && 
                            <>
                                <Button size='small' type='button' variant="outlined" sx={{ float: 'right', marginLeft: 1 }} onClick={exportLeads}>EXPORT TO EXCEL</Button>
                                <Button size='small' type='button' variant="outlined" color='success' sx={{ float: 'right'}} onClick={exportLeadsCSV}>EXPORT TO WHATSAPP CSV</Button>
                            </>}
                        </Box>
                    </Grid>
                </Box>
            </form>
        </>
    )
}

export default LeadSearchForm