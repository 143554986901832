import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { gIsSuperAdminUser } from '../../CommonUtils';

const UserSearchTable = (props) => {
    let { pageState, setPageState, openUserDialog, tableHeight } = props;

    const columns = [
        { field: 'name', headerName: 'Name', width: 160 },
        { field: 'email', headerName: 'Email', width: 260 },
        { field: 'mobilePhone', headerName: 'Mobile', width: 160 },
        { field: 'userRole', headerName: 'Role', width: 160 },
        { field: 'userStatus', headerName: 'Status', width: 160 },
        {
            field: 'userObj', headerName: '', width: 60, renderCell: (param) => {
                if (gIsSuperAdminUser()) {
                    return (
                        <>
                            <span onClick={() => openUserDialog(param.row.userObj)}><PersonIcon color='secondary' sx={{ cursor: 'pointer' }} /></span>
                        </>
                    )
                } else {
                    return (
                        <></>
                    )
                }
            }
        },
    ];

    return (
        <Box style={{ height: tableHeight, width: '100%' }}>
            <DataGrid
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                }}
                columns={columns}
                rows={pageState.data || []}
                rowCount={pageState.total || 0}
                loading={pageState.isLoading}
                rowsPerPageOptions={[50, 100]}
                pagination
                page={pageState.page - 1}
                pageSize={pageState.pageSize}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPageState(old => ({ ...old, page: newPage + 1 }))
                }}
                onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, page: 1, pageSize: newPageSize }))}
            />
        </Box>
    )
}

export default UserSearchTable