import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import desktopTheme from "../themeDesktop";


const HomePageButton = (props) => {
    const aboveXS = useMediaQuery(desktopTheme.breakpoints.up('sm'));

    return (
        <Box height={80} width={'90%'} py={0.2}>
            <Grid container height={'100%'} sx={{ backgroundColor: 'grey.400', borderRadius: '10px', boxShadow: '3', cursor: 'pointer' }}>
                <Grid item xs={2} flexWrap={'nowrap'}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRight: 1,
                        borderColor: 'grey.100',
                        backgroundColor: 'white', borderEndStartRadius: '10px', borderStartStartRadius: '10px',
                    }}
                >
                    <Box>{props.icon}</Box>
                </Grid>
                <Grid item xs={8} height={'100%'}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}
                >
                    <Box pr={1} pl={1} color={'background.dark'}>
                        <Typography textAlign={'left'} variant={'h6'}>{props.title} {props.count && `(${props.count})`}</Typography>
                        <Typography textAlign={'left'}>{props.subTitle}</Typography>
                    </Box>

                </Grid>
                {/* <Grid item xs={2} flexWrap={'nowrap'}
                    sx={{
                        backgroundColor: 'secondary.main', borderStartEndRadius: '10px', borderEndEndRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderLeft: 1,
                        borderColor: 'grey.100',
                    }}
                >
                    <Typography variant="h5" color={'background.default'}>{props.count}</Typography>
                </Grid> */}
            </Grid>
        </Box >
    )
}

export default HomePageButton;