import { apiClientPrivate } from './axios';
import { gGetError } from '../CommonUtils';

export async function getInvestors(investorObj) {
    try {
        if (!investorObj.provinceCodes) investorObj.provinceCodes = [];
        if (!investorObj.propertyTypeCodes) investorObj.propertyTypeCodes = [];

        let response = await apiClientPrivate.post(`/rest/investor/auf/list?pn=${investorObj.pn}&ipp=${investorObj.ipp}`,
            {
                id: investorObj.id,
                searchName: investorObj.searchName,
                investorPropertyTypes:
                    investorObj.propertyTypeCodes?.map(propertyTypeCode => {
                        return (
                            { propertyType: { code: propertyTypeCode } }
                        )
                    }),
                investorProvinces:
                    investorObj.provinceCodes?.map(provinceCode => {
                        return (
                            { province: { code: provinceCode } }
                        )
                    }),
                priceRangeMinimum: investorObj.priceRangeMinimum,
                priceRangeMaximum: investorObj.priceRangeMaximum,
                canAffordPrice: investorObj.canAffordPrice,
                phoneNumber: investorObj.phoneNumber,
                email: investorObj.email,
                status: {
                    code: investorObj.statusCode
                },
                keyword: investorObj.keyword,
                countriesToInvestIn: investorObj.countriesToInvestIn,
            }
        );
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function getInvestorsExports(investorObj, mode = "xlsx") {
    try {
        if (!investorObj.provinceCodes) investorObj.provinceCodes = [];
        if (!investorObj.propertyTypeCodes) investorObj.propertyTypeCodes = [];

        let postLink = mode === "csv" ? `/rest/investor/auf/list/exportCSV` : `/rest/investor/auf/list/export`;
        
        let response = await apiClientPrivate.post(postLink,
            {
                id: investorObj.id,
                searchName: investorObj.searchName,
                investorPropertyTypes:
                    investorObj.propertyTypeCodes?.map(propertyTypeCode => {
                        return (
                            { propertyType: { code: propertyTypeCode } }
                        )
                    }),
                investorProvinces:
                    investorObj.provinceCodes?.map(provinceCode => {
                        return (
                            { province: { code: provinceCode } }
                        )
                    }),
                priceRangeMinimum: investorObj.priceRangeMinimum,
                priceRangeMaximum: investorObj.priceRangeMaximum,
                canAffordPrice: investorObj.canAffordPrice,
                keyword: investorObj.keyword,
                phoneNumber: investorObj.phoneNumber,
                email: investorObj.email,
                status: {
                    code: investorObj.statusCode
                },
                countriesToInvestIn: investorObj.countriesToInvestIn,
            }
        );
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

/*export async function getInvestorsCSV(investorObj) { 
    try {
        if (!investorObj.provinceCodes) investorObj.provinceCodes = [];
        if (!investorObj.propertyTypeCodes) investorObj.propertyTypeCodes = [];

        let response = await apiClientPrivate.post(`/rest/investor/auf/list/exportCSV`,
            {
                id: investorObj.id,
                searchName: investorObj.searchName,
                investorPropertyTypes:
                    investorObj.propertyTypeCodes?.map(propertyTypeCode => {
                        return (
                            { propertyType: { code: propertyTypeCode } }
                        )
                    }),
                investorProvinces:
                    investorObj.provinceCodes?.map(provinceCode => {
                        return (
                            { province: { code: provinceCode } }
                        )
                    }),
                priceRangeMinimum: investorObj.priceRangeMinimum,
                priceRangeMaximum: investorObj.priceRangeMaximum,
                canAffordPrice: investorObj.canAffordPrice,
                keyword: investorObj.keyword,
                phoneNumber: investorObj.phoneNumber,
                email: investorObj.email,
                status: {
                    code: investorObj.statusCode
                },
                countriesToInvestIn: investorObj.countriesToInvestIn,
            }
        );
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}*/

export async function getInvestorById(investorId) {
    try {
        let response = await apiClientPrivate.get(`/rest/investor/auf/${investorId}`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function saveInvestor(investorObj) {
    try {
        let response = await apiClientPrivate.post(`/rest/investor/auf/create`,
            {
                id: investorObj.id,
                searchName: investorObj.searchName,
                investorPropertyTypes:
                    investorObj.propertyTypeCodes?.map(propertyTypeCode =>
                    ({
                        propertyType: {
                            code: propertyTypeCode,
                        }
                    })),
                investorProvinces:
                    investorObj.provinceCodes?.map(provinceCode =>
                    ({
                        province: {
                            code: provinceCode,
                        }
                    })),
                source: {
                    code: investorObj.sourceCode,
                },
                status: {
                    code: investorObj.statusCode
                },
                priceRangeMinimum: investorObj.priceRangeMinimum,
                priceRangeMaximum: investorObj.priceRangeMaximum,
                phoneNumber: investorObj.phoneNumber,
                email: investorObj.email,
                investorRemark: investorObj.investorRemark,
                rdVastgoedRemark: investorObj.rdVastgoedRemark,
                primaryPartyDetail: {
                    isPrimaryParty: investorObj.isPrimaryParty,
                    partyType: "INDIVIDUAL",
                    companyName: "",
                    firstName: investorObj.firstName,
                    lastName: investorObj.lastName,
                    phoneNumber: investorObj.phoneNumber,
                    email: investorObj.email,
                    remark: investorObj.remark,
                    idType: investorObj.idType,
                    idNumber: investorObj.idNumber,
                    address: {
                        street: investorObj.street,
                        houseNumber: investorObj.houseNumber,
                        houseNumberSuffix: investorObj.houseNumberSuffix,
                        city: investorObj.city,
                        postalCode: investorObj.postalCode,
                        countryCode: "NL",
                    }
                },
                alreadyInvesting: investorObj.alreadyInvesting,
                wantsLowYieldRental: investorObj.wantsLowYieldRental,
                wantsForeignInvestments: investorObj.wantsForeignInvestments,
                countriesToInvestIn: investorObj.countriesToInvestIn,
            }
        )
        return response

    } catch (err) {
        return gGetError(err)
    }
}

export async function getMatchingInvestorsPerLead(leadId) {
    try {
        let response = await apiClientPrivate.get(`/rest/investor/auf/matching-list?leadId=${leadId}`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function exportMatchingInvestorsToExcel(leadId) {
    try {
        let response = await apiClientPrivate.get(`/rest/investor/auf/matching-list/export?leadId=${leadId}`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function exportMatchingInvestorsToCSV(leadId) {
    try {
        let response = await apiClientPrivate.get(`/rest/investor/auf/matching-list/exportCSV?leadId=${leadId}`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export const getInvestorsToBeActivatedCount = async () => {
    let response = await apiClientPrivate.get(`/rest/investor/auf/getInvestorsToBeActivatedCount`);
    return response.data;
}

export const getAllActiveInvestorsCount = async () => {
    let response = await apiClientPrivate.get(`/rest/investor/auf/getAllActiveInvestorsCount`);
    return response.data;
}

export const getAllActiveInvestorEmails = async () => {
    let response = await apiClientPrivate.get(`/rest/investor/auf/getAllActiveInvestorsEmails`);
    return response.data;
}