import { apiClientPrivate } from './axios';
import { gGetError } from '../CommonUtils';
import moment from 'moment';

export async function getProspects(prospectObj) {
    try {
        if (!prospectObj.nextActionCodes) prospectObj.nextActionCodes = [];

        let response = await apiClientPrivate.post(`/rest/prospect/auf/list?pn=${prospectObj.pn}&ipp=${prospectObj.ipp}`,
            getPayload(prospectObj)
        );
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function getProspectsExports(prospectObj, mode = "xlsx") {
    let postLink = mode === "csv" ? `/rest/prospect/auf/list/exportCSV` : `/rest/prospect/auf/list/export`;
        
    try {
        if (!prospectObj.nextActionCodes) prospectObj.nextActionCodes = [];

        let response = await apiClientPrivate.post(postLink,
            getPayload(prospectObj)
        );
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

/*export async function getProspectsCSV(prospectObj) {
    try {
        if (!prospectObj.nextActionCodes) prospectObj.nextActionCodes = [];

        let response = await apiClientPrivate.post(`/rest/prospect/auf/list/exportCSV`,
            getPayload(prospectObj)
        );
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}*/

const getPayload = (prospectObj) => {
    return {
        id: prospectObj.prospectId,
        lead: {
            id: prospectObj.leadId,
            searchName: prospectObj.sellerSearchName,
            phoneNumber: prospectObj.sellerphoneNumber,
            email: prospectObj.sellerEmail,
            propertyAddress: {
                street: prospectObj.sellerStreet,
                houseNumber: prospectObj.sellerHouseNumber,
                houseNumberSuffix: prospectObj.sellerHouseNumberSuffix,
                postalCode: prospectObj.sellerPostalCode                        ,
                city: prospectObj.sellerCity,
            },
            propertyProvince: {
                code: prospectObj.sellerProvinceCode,
            }
        },
        investor: {
            id: prospectObj.investorId,
            searchName: prospectObj.investorSearchName,
            phoneNumber: prospectObj.investorPhoneNumber,
            email: prospectObj.investorEmail,
        },
        nextAction:
            prospectObj.nextActionCodes?.map(nextActionCode => {
                return (
                    { code: nextActionCode }
                )
            }),
        nextActionDateFrom: prospectObj.nextActionDateFrom ? moment(prospectObj.nextActionDateFrom, "YYYY-MM-DD").format("DD/MM/YYYY") : '',
        nextActionDateTo: prospectObj.nextActionDateTo ? moment(prospectObj.nextActionDateTo, "YYYY-MM-DD").format("DD/MM/YYYY") : '',
    }
}
// export async function getInvestorById(investorId) {
//     try {
//         let response = await apiClientPrivate.get(`/rest/investor/auf/${investorId}`);
//         return response.data;
//     } catch (err) {
//         return gGetError(err)
//     }
// }

export async function saveProspect(leadId, investorId) {
    try {
        let response = await apiClientPrivate.post(`/rest/prospect/auf/create`,
            {
                lead: {
                    id: leadId
                },
                investor: {
                    id: investorId
                }
            }
        )
        return response.data

    } catch (err) {
        return gGetError(err)
    }
}

export const getManageProspectCount = async () => {
    let response = await apiClientPrivate.get(`/rest/prospect/auf/getManageProspectCount`);
    return response.data;
}

