import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gPrint } from '../../../CommonUtils';
import FormInput from '../../../components/FormInput';
import PageHeader from '../../../components/PageHeader';
import UserAlert from '../../../components/UserAlert';
import { exportMatchingInvestorsToExcel, exportMatchingInvestorsToCSV, getMatchingInvestorsPerLead } from '../../../services/InvestorService';
import { getLeadById } from '../../../services/LeadService';
import { saveRecipientList } from '../../../services/RecipientListService';
import InvestorDetails from '../../investor/investor-search/InvestorDetails';

// Inline styles
const styles = {
    gridItemPadding: {
        px: 0.1,
        py: 0.5
    },
    gridItemButtonPadding: {
        px: 0.1,
        py: 1.0
    }
};

const leadIcon = <ContentPasteIcon />
const investorIcon = <PersonIcon />

const HotLeads = (props) => {
    let { leadId, showLeadDetails } = props;
    const userAlert = useRef(null)
    const navigate = useNavigate();

    const initialValues = {
        id: '',
        searchName: '',
        streetHouseNumberSuffix: '',
        postalCodeCity: '',
        province: '',
        sellerRemark: '',
        priceDesired: '',
        propertyType: '',
        rdVastgoedRemark: '',
        recipientListName: '',
    }
    const [values, setValues] = useState(initialValues)
    const [errors, setErrors] = useState([])
    const [investorId, setInvestorId] = useState('')
    const [isInvestorDetailsDialogOpen, setIsInvestorDetailsDialogOpen] = useState(false)
    const [matchingInvestors, setMatchingInvestors] = useState([]);
    const [emailList, setEmailList] = useState('');

    const [pageState, setPageState] = useState({
        page: 1,
        pageSize: 25
    })

    const handleInputChanage = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const columns = [
        { field: 'searchName', headerName: 'Search Name', flex: 1 },
        { field: 'phoneNumber', headerName: 'Phone No.', width: 120 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'priceRangeMinimum', headerName: 'Price Range Min.', width: 110 },
        { field: 'priceRangeMaximum', headerName: 'Price Range Max.', width: 110 },
        { field: 'investorPropertyTypes', headerName: 'Property Types', width: 150 },
        { field: 'investorProvinces', headerName: 'Located in Provinces', width: 150 },
        {
            field: 'investorId', headerName: '', width: 30, renderCell: (param) => {
                return (
                    <>
                        <span onClick={() => openInvestorDetailsDialog(param.value)}><PersonIcon sx={{ cursor: 'pointer' }} /></span>
                    </>
                )
            }
        },
    ];

    const getLeadDetails = async () => {
        let response = await getLeadById(leadId);
        let tempValues = {
            ...response,
            investorId: response.id,
            searchName: response.searchName,
            streetHouseNumberSuffix: (`${gPrint(response.propertyAddress.street)} ${gPrint(response.propertyAddress.houseNumber)} ${gPrint(response.propertyAddress.houseNumberSuffix)}`).trim(),
            province: response.propertyProvince?.name,
            postalCodeCity: (`${gPrint(response.propertyAddress.postalCode)} ${gPrint(response.propertyAddress.city)}`).trim(),
            sellerRemark: response.sellerRemark,
            priceDesired: response.priceDesired,
            propertyType: response.leadPropertyTypes ? response.leadPropertyTypes[0]?.propertyType.name : '',
            rdVastgoedRemark: response.rdVastgoedRemark,
        }
        setValues(tempValues);
    }
    useEffect(() => {
        if (leadId) getLeadDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadId])

    const mapToFlatStructure = (responseContent) => {
        let emails = '';
        return responseContent.map(row => {
            emails = `${row.email}; ${emails}`
            let rows = ({
                id: row.id,
                searchName: row.searchName,
                phoneNumber: row.phoneNumber,
                email: row.email,
                priceRangeMinimum: row.priceRangeMinimum?.toLocaleString(),
                priceRangeMaximum: row.priceRangeMaximum?.toLocaleString(),
                investorPropertyTypes: row.investorPropertyTypes.map(propT => ` ${propT.propertyType.code}`),
                investorProvinces: row.investorProvinces.map(prov => ` ${prov.province.code}`),
                investorId: row.id,
            });
            emails = emails.replace(/;\s*$/, ""); // remove trailling semi-colon (;)
            setEmailList(emails);
            return rows;
        })
    }

    const findMatches = async () => {
        userAlert.current.clearAll();

        if (!values.priceDesired || !values.propertyType) {
            userAlert.current.setError('Some fields required for the matching process have not yet been populated in the lead.')
            return false;
        }

        let response = await getMatchingInvestorsPerLead(leadId);
        setMatchingInvestors(mapToFlatStructure(response.content));
    }

    const exportMatchesToExcel = async () => {
        userAlert.current.clearAll();
        let response = await exportMatchingInvestorsToExcel(leadId);

        if (response) {
            let a = document.createElement("A");
            a.setAttribute("href", 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response);
            a.setAttribute('download', 'matching-investors-export.xlsx');
            document.body.appendChild(a);
            a.click();
        }
    }

    const exportMatchesToCSV = async () => {
        userAlert.current.clearAll();
        let response = await exportMatchingInvestorsToCSV(leadId);

        if (response) {
            let a = document.createElement("A");
            a.setAttribute("href", 'data:text/csv;charset=UTF-8,' + response);
            a.setAttribute('download', 'matching-investors-export.csv');
            document.body.appendChild(a);
            a.click();
        }
    }

    // const copyEmailListToCLipboard = async () => {
    //     userAlert.current.clearAll();
    //     if (emailList) {
    //         navigator.clipboard.writeText(emailList);
    //         userAlert.current.setInfo('Contact list copied to clipboard.')
    //     } else {
    //         userAlert.current.setWarn('No email contacts to copy. May be try \'FIND MATCHES\' first.')
    //     }
    // }

    const createMailing = async () => {
        userAlert.current.clearAll();
        setErrors({})
        if (!emailList) {
            userAlert.current.setWarn('No email contacts to copy. May be try \'FIND MATCHES\' first.')
            return false;
        } else if (!values.recipientListName) {
            setErrors({
                recipientListName: 'Enter a Recipient List Name.'
            })
            return false;
        }
        let response = await saveRecipientList({
            did: '',
            name: values.recipientListName,
            listOfEmails: emailList
        })

        if (!response.error) {
            navigate('/create-mailing?recipientListDid=' + response.did)
        } else {
            userAlert.current.setWarn(response.error.message)
        }
    }

    const openInvestorDetailsDialog = (investorId) => {
        userAlert.current.clearAll();
        setInvestorId(investorId)
        setIsInvestorDetailsDialogOpen(true)
    }


    return (
        <>
            <section>
                <UserAlert ref={userAlert} />

                <PageHeader title='Manage Hot Leads - Find Lead Investor Matches' icon={leadIcon} />

                {/* form */}
                <form autoComplete='off' noValidate id='frmRegisterVendor'>
                    <Box mt={0.0} bgcolor={'background.paper'}>
                        <Grid container>

                            {/* column 1 */}
                            <Grid item xs={12} md={4}>
                                <Grid container px={0.7}>
                                    <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                        <FormInput label='Search Name' value={values.searchName} name="searchName" disabled={true} />
                                    </Grid>
                                    <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                        <FormInput label='Street/No./Suffix' value={values.streetHouseNumberSuffix} name='streetHouseNumberSuffix' disabled={true} />
                                    </Grid>
                                    <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                        <FormInput label='Postcode/City' value={values.postalCodeCity} name='postalCodeCity' disabled={true} />
                                    </Grid>
                                    <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                        <FormInput label='Province' value={values.province} name='province' disabled={true} />
                                    </Grid>
                                    <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                        <FormInput name="sellerRemark" value={values.sellerRemark} label="Seller Remark" multiline={true} rows={3} maxRows={4} disabled={true} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* column 2 */}
                            <Grid item xs={12} md={4}>
                                <Grid container px={0.7}>
                                    <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                        <Grid container>
                                            <Grid item xs={9}>
                                                <FormInput label='Lead Id' name="id" value={values.id} disabled={true} />
                                            </Grid>
                                            <Grid item xs={3} sx={{ paddingTop: '4px' }}>
                                                <Button size='small' type='button' variant="outlined" sx={{ float: 'right' }} onClick={() => showLeadDetails(leadId)}>DETAILS</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                        <Box sx={{ height: '37px' }}></Box>
                                    </Grid>
                                    <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                        <FormInput label='Desired Price' name="priceDesired" value={values.priceDesired} disabled={true} />
                                    </Grid>
                                    <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                        <FormInput label='Property Type' name="propertyType" value={values.propertyType} disabled={true} />
                                    </Grid>
                                    <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                        <FormInput name="rdVastgoedRemark" value={values.rdVastgoedRemark} label="RD-Vastgoed Remark" multiline={true} rows={3} maxRows={4} disabled={true} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* column 3 */}
                            <Grid item xs={12} md={4} pr={1}>
                                <Grid container px={0.7} sx={{ height: '100%' }} justifyContent='space-between'>
                                    <Grid item flexGrow={1} xs={12}>
                                        <Button size='small' type='button' variant="contained" sx={{ width: '100%', my: '4px' }} onClick={findMatches}>FIND MATCHES</Button>
                                        <Button size='small' type='button' variant="outlined" sx={{ width: '100%', my: '4px' }} onClick={exportMatchesToExcel}>EXPORT TO EXCEL</Button>
                                        <Button size='small' type='button' variant="outlined" color='success' sx={{ width: '100%', my: '4px' }} onClick={exportMatchesToCSV}>EXPORT TO WHATSAPP CSV</Button>
                                    </Grid>
                                    <Grid container alignItems={'flex-end'}>
                                        <Grid item flexGrow={1} xs={12}>
                                            <FormInput onChange={handleInputChanage} label='Recipient List Name' name="recipientListName" value={values.recipientListName} error={errors.recipientListName} />
                                            {/* <Button size='small' type='button' variant="outlined" sx={{ width: '100%', margin: '4px' }} onClick={copyEmailListToCLipboard}>COPY CONTACT LIST</Button> */}
                                            <Button size='small' type='button' variant="contained" sx={{ width: '100%', my: '4px' }} onClick={createMailing}>CREATE MAILING</Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                </form>

                <Box mt={1} />

                <PageHeader title='Matching Investors' icon={investorIcon} /*rightSideComponent={<ShowExtendedResults />}*/ />

                {/* table */}
                <Box style={{ height: 220, width: '100%' }}>
                    <DataGrid
                        rows={matchingInvestors}
                        columns={columns}
                        pageSize={pageState.pageSize}
                        onPageChange={(newPage) => {
                            setPageState(old => ({ ...old, page: newPage + 1 }))
                        }}
                        onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, page: 1, pageSize: newPageSize }))}
                    />
                </Box>
            </section>

            {/* investor details modal */}
            <InvestorDetails investorId={investorId} isInvestorDetailsDialogOpen={isInvestorDetailsDialogOpen} setIsInvestorDetailsDialogOpen={setIsInvestorDetailsDialogOpen} />
        </>
    )
}

export default HotLeads