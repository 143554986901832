import { NextPlan } from "@mui/icons-material";
import { USER_ROLES } from "./constants/AppConsts";

export const gGetError = (err) => {
    if (err.response) {
        return (
            {
                error: {
                    code: err.response.status,
                    message: err.response.data,
                }
            }
        )
    } else {
        return (
            {
                code: err.code,
                message: err.message,
            }
        )
    }

}

// Print values to user interface (acoid showing undefined, NaN etc.)
export const gPrint = (value) => {
    return value && value !== 'undefined' ? value : '';
}

export const gIsAdminUser = () => {
    let uRole = localStorage.getItem('USER_ROLE');
    return (uRole && uRole === USER_ROLES.ADMINISTRATOR.value || uRole === USER_ROLES.SUPER_ADMIN.value);
}

export const gIsSuperAdminUser = () => {
    let uRole = localStorage.getItem('USER_ROLE');
    return (uRole && uRole === USER_ROLES.SUPER_ADMIN.value);
}





