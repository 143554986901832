import { gGetError } from '../CommonUtils';
import { apiClientPrivate } from './axios';

export async function getAttachments(ownerClass, ownerId) {
    try {
        let response = await apiClientPrivate.get(`/rest/file-attachment/auf/?ownerType=${ownerClass}&ownerID=${ownerId}`);
        return response.data
    } catch (err) {
        return gGetError(err)
    }
}

export async function uploadAttachment(ownerClass, ownerId, folderPath, fileName, file) {
    try {
        let fileSplit = ('' + file).split(",");
        let response = await apiClientPrivate.post(`/rest/file-attachment/auf/create`,
            {
                ownerID: ownerId,
                ownerType: ownerClass,
                folderPath: folderPath,
                filename: fileName,
                contentBase64Encoded: fileSplit[1],
                mimeType: fileSplit[0].replace("data:", "")

            }
        );
        return response.data
    } catch (err) {
        return gGetError(err)
    }
}

export async function deleteAttachment(ownerClass, ownerId, attachment) {
    try {
        let response = await apiClientPrivate.delete(`/rest/file-attachment/auf/`,
            {
                data: {
                    ownerID: ownerId,
                    ownerType: ownerClass,
                    folderPath: attachment.folderPath ? attachment.folderPath : '',
                    filename: attachment.filename
                }
            }
        );
        return response.data
    } catch (err) {
        return gGetError(err)
    }
}

export async function downloadAttachment(ownerClass, ownerId, attachment) {
    try {
        let response = await apiClientPrivate.post(`/rest/file-attachment/auf/download`,
            {
                ownerID: ownerId,
                ownerType: ownerClass,
                folderPath: attachment.folderPath ? attachment.folderPath : '',
                filename: attachment.filename
            }
        );
        return response.data
    } catch (err) {
        return gGetError(err)
    }
}

