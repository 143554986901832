import React from 'react'
import { TextField } from '@mui/material'
import { gPrint } from '../CommonUtils'

// const StyledTextField = styled(TextField, {})({
//     width: '100%',
// })

const FormInput = (props) => {
    return (
        <TextField
            InputProps={{
                readOnly: props.readOnly
            }}
            color='secondary'
            name={props.name}
            size='small' 
            sx={{ backgroundColor: '#fff' }}
            label={props.label}
            value={gPrint(props.value)}
            onChange={props.onChange}
            fullWidth={props.fullWidth ? props.fullWidth : true}
            type={props.type ? props.type : 'text'}
            rows={props.rows}
            multiline={props.multiline}
            required={props.required}
            disabled={props.disabled}
            autoComplete={props.autoComplete}
            placeholder={props.placeholder ? props.placeholder : ''}
            InputLabelProps={{ shrink: props.shrink ? props.shrink === 'true' : undefined }}
            {...(props.error && { error: true, helperText: props.error })}
        />
    )
}

export default FormInput