import { Dialog, DialogContent } from '@mui/material';
import LeadUpdate from '../lead-update/LeadUpdate';
import CloseIcon from '@mui/icons-material/Close';

const LeadDetails = (props) => {

    return (
        <Dialog open={props.isDetailsDialogOpen} onClose={props.closeLeadDetailsDialog} maxWidth={'lg'} >
            <DialogContent sx={{width:'90vw', maxWidth:'lg'}} >
                <span onClick={props.closeLeadDetailsDialog} ><CloseIcon sx={{float:'right', cursor:'pointer'}}/></span>
                <LeadUpdate leadId={props.leadId} />
            </DialogContent>
        </Dialog>
    )
}

export default LeadDetails