import React from 'react'
import { Breadcrumbs, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const Breadcrumb = (props) => {
    const breadcrumbs = props.breadcrumbs;

    return (
        <Box mb={1}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs.map(bc => {
                    return (
                        <Typography key="3" color="info.main">
                            {bc}
                        </Typography>
                    )
                })}
            </Breadcrumbs>
        </Box>
    )
}

export default Breadcrumb;