import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid } from '@mui/material'
import { Box } from '@mui/system'
import PersonIcon from '@mui/icons-material/Person'
import FormInput from '../../components/FormInput'
import FormSelect from '../../components/FormSelect'
import PageHeader from '../../components/PageHeader'
import UserAlert from '../../components/UserAlert'
 import { USER_ROLES, USER_STATUS } from '../../constants/AppConsts'
import { saveUser, getNoOfSuperUsers } from '../../services/UserService'

// Inline styles
const styles = {
    gridItemPadding: {
        px: 0.1,
        py: 0.5
    }
};

const User = (props) => {
    const iconUser = <PersonIcon />
    const userAlert = useRef(null);
    const [user, setUser] = useState(props.user);
    const [supeAdminCount, setSupeAdminCount] = useState(1);
    const [changeCount, setChangeCount] = useState(0)
    const initialValues = {
        did: '',
        name: '',
        mobilePhone: '',
        email: '',
        password: '',
        userRole: null,
        userStatus: null
    }
    const [values, setValues] = useState(initialValues)
    const [errors, setErrors] = useState([]);

    const handleInputChanage = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const getUserDetails = async () => {
        //let response = await getUserById(userId);

        let tempValues = {
            did: user.did,
            email: user.email,
            name: user.name,
            mobilePhone: user.mobilePhone,
            userRole: user.userRole,
            userStatus: user.userStatus,
        }
        setValues({
            ...values,
            ...tempValues
        });
    }

    useEffect(() => {
        user && getUserDetails();
    }, [user])

    const validateUser = () => {
        userAlert.current.clearAll()
        let temp = {}
        //temp.name = values.name !== '' ? '' : 'Name is required'
        temp.mobilePhone = (!values.mobilePhone || (/^[\+]?[00]?[0-9]{2}[\-]?[0-9]{8,12}$/).test(values.mobilePhone)) ? '' : 'A valid phone number is required (minimum 10 digits)'
        temp.email = values.email === '' ? 'Email is required' 
        : 
        (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(values.email) ? '' : 'A valid email address is required'
        temp.userRole = (values.userRole !== null && values.userRole.length > 0) ? '' : 'Role is required'
        temp.userStatus = (values.userStatus !== null && values.userStatus.length > 0) ? '' : 'Status is required'

        const editUser = values?.did && values?.did > 0
        // Password mandotory only for new user
        if(!editUser){
            temp.password = values.password !== '' ?
            values.password.length < 8 ? "Password length should be at least 8 characters" : ""
             : 'Password is required'
        }
        //temp.mobilePhone = (values.mobilePhone === '' || (/^[\+]?[00]?[0-9]{2}[\-]?[0-9]{8,12}$/).test(values.mobilePhone)) ? '' : 'Phone noumber has to be valid (minimum 10 digits)'
        //temp.email = (values.email === '' || (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(values.email)) ? '' : 'A valied email address is required'

        setErrors({ ...temp })

        return (Object.values(temp).every(x => x === "")) ? true : false;
    }

    const _saveUser = async () => {
        userAlert.current.clearAll()
        if (validateUser()) {
            let response = await saveUser(
                values
            );

            if (!response.error) {
                 setValues(
                     { ...values, did: response.did }
                 )
                 userAlert.current.setInfo('Save done.')
                 setChangeCount(cnt => ++cnt)
            }
            else userAlert.current.setError(response.error.message)
        }
    }

    const _getNoOfSuperUsers = async () => {
        let response = await getNoOfSuperUsers(
            values
        );

        if (!response.error) {
            setSupeAdminCount(response)
        }
        else{

        }
    }

    useEffect(() => {
        _getNoOfSuperUsers()

    }, [changeCount, user])

    const roleItems = [
        // { id: '', name: '[ unselect ]' },
        { id: 'USER', name: 'User' },
        { id: 'ADMINISTRATOR', name: 'Admin' },
        { id: 'SUPER_ADMIN', name: 'Super admin' },
    ]
    
    const statusItems = [
        // { id: '', name: '[ unselect ]' },
        { id: 'ACTIVE', name: 'Active' },
        { id: 'INACTIVE', name: 'Inactive' },
    ]

    return (
        <>
            <UserAlert ref={userAlert} />

            <PageHeader title={'Add / Modify User'} icon={iconUser} />
            <form autoComplete='off' noValidate id='frmRegisterVendor'>
                <Box mt={0.0} bgcolor={'background.paper'}>
                    <Grid container>

                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Name' value={values.name} name='name' error={errors.name} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Phone No.' value={values.mobilePhone} name="mobilePhone" error={errors.mobilePhone} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Email' value={values.email} name="email" error={errors.email}  readOnly={values.did>0} required={true}/>
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormSelect
                                        required={true}
                                        name="userRole"
                                        label="Role *"
                                        value={values.userRole || []}
                                        onChange={handleInputChanage}
                                        items={roleItems}
                                        error={errors.userRole}
                                        disabled={ supeAdminCount == 1 && user.userRole == USER_ROLES.SUPER_ADMIN.value && user.userStatus == USER_STATUS.ACTIVE.value }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormSelect
                                        required={true}
                                        name="userStatus"
                                        label="Status *"
                                        value={values.userStatus || []}
                                        onChange={handleInputChanage}
                                        items={statusItems}
                                        error={errors.userStatus}
                                        disabled={ supeAdminCount == 1 && user.userRole == USER_ROLES.SUPER_ADMIN.value && user.userStatus == USER_STATUS.ACTIVE.value }
                                    />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput type='password' onChange={handleInputChanage} label='Password' value={values.password} name='password' error={errors.password} autoComplete={"new-password"} required={true}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent={'flex-end'}>
                        <Grid item>
                            <Box p={1} width={'100%'}>
                                <Button size='small' type='button' variant="contained" sx={{ float: 'right', marginLeft: 1 }} onClick={() => _saveUser(false)}>SAVE</Button>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{ height: 10 }} ></Box>
                </Box>
            </form>
        </>
    )
}

export default User