import { gGetError } from '../CommonUtils';
import { apiClientPrivate } from './axios';

export async function getAddressByPostalCodeAndHouseNumber(addressObj) {
    try {
        let response = await apiClientPrivate.get(`/rest/address/auf/by-postalcode-housenumber-new?postalCode=${addressObj.postalCode}
        &houseNumber=${addressObj.houseNumber}&houseNumberSuffix=${addressObj.houseNumberSuffix}`);

        if (response) {
            response.data.houseNumberSuffix = (response.data.houseNumberSuffix) ? response.data.houseNumberSuffix : '';
            return response.data
        }
        return null;
    } catch (err) {
        return gGetError(err)
    }
}


