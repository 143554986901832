import { Container, ThemeProvider, useMediaQuery } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import DesktopAppBar from '../components/DesktopAppBar'
import DesktopFooter from '../components/DesktopFooter';
import desktopTheme from '../themeDesktop';

const DesktopLayout = () => {

    const aboveSM = useMediaQuery(desktopTheme.breakpoints.up('md'));

    return (
        <ThemeProvider theme={desktopTheme}>
            <section className='desktop-background'>
                <DesktopAppBar />
                <Container maxWidth="lg" sx={{padding:0, marginTop:'62px'}}>
                    <Outlet />
                </Container>
                {aboveSM && <DesktopFooter />}
            </section>
        </ThemeProvider>
    )
}

export default DesktopLayout