import React from 'react'
import { useLocation, Navigate, Outlet } from "react-router-dom";

// props can be passed to this and may be do ROLE based authorization if required
// https://www.youtube.com/watch?v=oUZjO00NkhY&list=PL0Zuz27SZ-6PRCpm9clX0WiBEMB70FWwd&index=4
const RequireAuth = () => {
    // const authContext = useAuth();
    const location = useLocation();

    // if page is refreshed the authContext is going to loose it data. But this would be the most secure method for single page app where page is never refreshed
    // other option may be to use localStorage to store the ACCESS_TOKEN
    return (
        // authContext.auth.ACCESS_TOKEN ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
        localStorage.getItem('ACCESS_TOKEN') ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
    )
}

export default RequireAuth