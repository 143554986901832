import { AppBar, Toolbar, Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const LoginAppBar = () => {

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position='static' elevation={0} color={'grey'}>
                <Toolbar>
                    <Grid container justifyContent={'space-between'} alignItems='center'>
                        <Grid item >
                            <img src='/images/rd-vastgoed.svg' height={50} alt='' />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default LoginAppBar
