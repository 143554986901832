import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { gIsAdminUser } from '../../../CommonUtils';
import desktopTheme from '../../../themeDesktop';

const LeadSearchTable = (props) => {
    let { pageState, setPageState, showLeadDetails, showFindInvestors, gotoCreateProspect } = props;

    const columns = [
        { field: 'nextAction', headerName: 'Next Action', width: 150 },
        { field: 'nextActionDate', headerName: 'Next Action Date', width: 120 },
        { field: 'searchName', headerName: 'Search Name', flex: 1 },
        { field: 'street', headerName: 'Street' },
        { field: 'houseNumber', headerName: 'House No.' },
        { field: 'city', headerName: 'City' },
        { field: 'priceDesired', headerName: 'Desired Price' },
        { field: 'phoneNumber', headerName: 'Phone No.', renderCell: (param) => { 
            return (<a href={`tel:${param.value}`}><Box sx={{cursor: 'pointer', textDecoration:'underline black', color:'#000'}}>{param.value}</Box></a>)
        }},
        { field: 'email', headerName: 'Email', width: 150 },
        {
            field: 'leadId', headerName: '', width: 90, renderCell: (param) => {
                return (
                    <Box sx={{textAlign:'center', margin:'auto', display:'inline-flex'}}>
                        <span onClick={() => showLeadDetails(param.value)}><ContentPasteOutlinedIcon color='info' sx={{ cursor: 'pointer' }} /></span>
                        {gIsAdminUser() &&
                            <>
                                <span onClick={() => showFindInvestors(param)}><ContentPasteOutlinedIcon color='error' sx={{ cursor: 'pointer', marginLeft: '5px' }} /></span>
                                <span onClick={() => gotoCreateProspect(param)}><AddCircleOutlineOutlinedIcon sx={{ cursor: 'pointer', marginLeft: '5px', color: desktopTheme.palette.success.main}} /></span>
                            </>
                        }
                    </Box>
                )
            }
        },
    ];

    return (
        <Box style={{ height: 500, width: '100%' }}>
            <DataGrid
                columns={columns}
                rows={pageState.data}
                rowCount={pageState.total}
                loading={pageState.isLoading}
                rowsPerPageOptions={[50, 100]}
                pagination
                page={pageState.page - 1}
                pageSize={pageState.pageSize}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPageState(old => ({ ...old, page: newPage + 1 }))
                }}
                onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, page: 1, pageSize: newPageSize }))}
            />
        </Box>
    )
}

export default LeadSearchTable