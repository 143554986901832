import { gGetError } from "../CommonUtils";
import { apiClientPrivate } from "./axios";

export async function getWhatsappSettings(settingsDid) {
    try {
        let response;
        if (settingsDid) {
            response = await apiClientPrivate.get(`/rest/whatsapp-settings/auf/list?did=${settingsDid}`);
        } else {
            response = await apiClientPrivate.get(`/rest/whatsapp-settings/auf/list`);
        }
        return response.data;

    } catch (err) {
        return gGetError(err);
    }   
}

export async function saveWhatsappSettings(whatsappSettingsObj) {
    let response = await apiClientPrivate.post(`/rest/whatsapp-settings/auf/update`,
    {
        did: whatsappSettingsObj.did,
        description: whatsappSettingsObj.description,
        service_end_point: whatsappSettingsObj.service_end_point,
        service_provider: whatsappSettingsObj.service_provider,
        api_key: whatsappSettingsObj.api_key,
        template_id: whatsappSettingsObj.template_id,
        template_params: whatsappSettingsObj.template_params,
        is_active: whatsappSettingsObj.is_active
    });

    return response.data;
    
}