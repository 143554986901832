import axios from 'axios'
import { BASE_URL, HTTP_TIMEOUT } from '../constants/AppConsts'

const apiClient = axios.create({
    baseURL: BASE_URL,
    timeout: HTTP_TIMEOUT,
    // headers: {
    //     'Accept': 'application/json',
    //     //'Authorization': `Bearer ${jwtToken}`
    // }
});

const apiClientPrivate = axios.create({
    baseURL: BASE_URL,
    timeout: HTTP_TIMEOUT,
    // headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${jwtToken}`
    // },
    withCredentials: true
});

/**
 * Set ACCESS_TOKEN to Authorization header
 */
apiClientPrivate.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * Catch the AunAuthorized Request
 */
apiClientPrivate.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401) {
        window.location = '/';
    } else {
        return Promise.reject(error);
    }
});

export { apiClient, apiClientPrivate }
