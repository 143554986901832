// UserSearchForm.js

import React from 'react'
import { Button, Grid } from '@mui/material'
import { Box } from '@mui/system'
import FormInput from '../../components/FormInput'
import FormSelect from '../../components/FormSelect'
import { gIsSuperAdminUser } from '../../CommonUtils'

// Inline styles
const styles = {
    gridItemPadding: {
        px: 0.1,
        py: 0.5
    }
};

const UserSearchForm = (props) => {
    let { userAlert, values, errors, openUserDialog, clearForm, searchUsers, handleInputChanage } = props;

    const roleItems = [
        { id: '', name: '[ unselect ]' },
        { id: 'USER', name: 'User' },
        { id: 'ADMINISTRATOR', name: 'Admin' },
        { id: 'SUPER_ADMIN', name: 'Super admin' },
    ]
    
    const statusItems = [
        { id: '', name: '[ unselect ]' },
        { id: 'ACTIVE', name: 'Active' },
        { id: 'INACTIVE', name: 'Inactive' },
    ]

    return (
        <>
            {userAlert}

            <form autoComplete='off' noValidate id='frmRegisterVendor'>
                <Box mt={0.0} bgcolor={'background.paper'}>
                    <Grid container>

                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Name' value={values.name} name='name' error={errors.name} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Phone No.' value={values.mobilePhone} name="mobilePhone" error={errors.mobilePhone} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Email' value={values.email} name="email" error={errors.email} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormSelect
                                        name="userRole"
                                        label="Role"
                                        value={values.userRole || []}
                                        onChange={handleInputChanage}
                                        items={roleItems}
                                        error={errors.userRole}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormSelect
                                        name="userStatus"
                                        label="Status"
                                        value={values.userStatus || []}
                                        onChange={handleInputChanage}
                                        items={statusItems}
                                        error={errors.userStatus}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Box py={0.2} mx={1} mb={1} width={'100%'}>
                            {gIsSuperAdminUser() &&
                                <>
                                    <Button size='small' type='button' variant="contained" sx={{ float: 'right', marginLeft: 1 }} onClick={openUserDialog}>ADD</Button>
                                    <Button size='small' type='button' variant="outlined" sx={{ float: 'right', marginLeft: 1 }} onClick={clearForm}>CLEAR</Button>
                                    <Button size='small' type='button' variant="contained" sx={{ float: 'right' }} onClick={searchUsers}>SEARCH</Button>
                                </>
                            }
                        </Box>
                    </Grid>
                </Box>
            </form>
        </>
    )
}

export default UserSearchForm