import { gGetError } from '../CommonUtils';
import { apiClientPrivate } from './axios';

export async function getAllProvinces() {
    try {
        let response = await apiClientPrivate.get(`/rest/province/auf/list`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function getSourceByClass(sourceClass) {
    try {
        let response = await apiClientPrivate.get(`/rest/source/auf/by-class?class=${sourceClass}`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function getActionsByClass(actionClass) {
    try {
        let response = await apiClientPrivate.get(`/rest/action/auf/by-category?actionClass=${actionClass}`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function getAllPropertyTypes() {
    try {
        let response = await apiClientPrivate.get(`/rest/propertyType/auf/list`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function getAllSalesStarts() {
    try {
        let response = await apiClientPrivate.get(`/rest/sales-start/auf/list`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}