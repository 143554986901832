import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { Box, Button, Grid, styled, TextField, Tooltip, Typography } from '@mui/material';
import React from 'react';
import FormInput from './FormInput';
import { useRef } from 'react';

// use mui styles
const StyledTable = styled('table')(({ theme }) => ({
    width: '100%',
    '& th': {
        paddingLeft: 5,
        backgroundColor: theme.palette.grey[300],
        fontWeight: 400,
    },
    '& td': {
        paddingLeft: 5,
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: theme.palette.background.default
    }
}));

const DeleteIcon = styled(DeleteForeverOutlinedIcon)(({ theme }) => ({
    color: theme.palette.error.main,
}));



const AttachmentFileListView = (props) => {
    let { userAlert, values, attachments, fileRef, downloadAttachment, deleteAttachment, ownerClass, ownerId, handleUploadClick, handleFileChange, handleInputChanage } = props;

    return (
        <>
            {userAlert}

            <StyledTable>
                <thead>
                    <tr style={{ textAlign: 'left' }}>
                        <th style={{ width: '60%' }}><Typography>Attachment File Name</Typography></th>
                        <th style={{ width: '35%' }}><Typography>Folder Path</Typography></th>
                        <th style={{ width: '50px' }}>&nbsp;</th>
                        <th style={{ width: '50px' }}>&nbsp;</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        attachments.map(attachment => (
                            <tr key={attachment.did}>
                                <td><Typography>{attachment.filename}</Typography></td>
                                <td><Typography>{attachment.folderPath}</Typography></td>
                                <td style={{ textAlign: 'center', cursor: 'pointer' }}><Tooltip title="Download file" ><span onClick={() => downloadAttachment(ownerClass, ownerId, attachment)}><CloudDownloadOutlinedIcon /></span></Tooltip></td>
                                <td style={{ textAlign: 'center', cursor: 'pointer' }}><Tooltip title="Delete file" ><span onClick={() => deleteAttachment(ownerClass, ownerId, attachment)}><DeleteIcon /></span></Tooltip></td>
                            </tr>
                        ))
                    }

                </tbody>

            </StyledTable>
            <Box height={'5px'} />
                <form ref={fileRef} >
            <Grid container alignItems={'center'} direction={'row-reverse'}>
                    <Grid item flex>
                        <Button size='small' type='button' variant="contained" sx={{ marginLeft: 1 }} onClick={handleUploadClick}>UPLOAD FILE</Button>
                    </Grid>
                    <Grid item flex>
                        <FormInput name='folderPath' value={values.folderPath} label='Folder path' onChange={handleInputChanage} />
                    </Grid>
                    <Grid item flex>
                        <TextField size='small' type='file' name={'fileName'} onChange={handleFileChange} sx={{ marginLeft: 1, backgroundColor: '#fff' }} />
                    </Grid>
            </Grid>
                </form>
        </>
    )
}

export default AttachmentFileListView