import { AppBar, Toolbar, Stack, Button, Grid, Menu, MenuItem, Fade, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { gIsAdminUser, gIsSuperAdminUser } from '../CommonUtils';




const DesktopAppBar = () => {
    const navigate = useNavigate();

    //const [menuDisplay, setMenuDisplay] = useState('visible');

    const [anchorElLead, setAnchorElLead] = useState(null);
    const openLead = Boolean(anchorElLead);
    const handleClickLead = (event) => {
        setAnchorElLead(event.currentTarget);
    }

    const [anchorElProspect, setAnchorElProspect] = useState(null);
    const openProspect = Boolean(anchorElProspect);
    const handleClickProspect = (event) => {
        setAnchorElProspect(event.currentTarget);
    }

    const [anchorElInvestor, setAnchorElInvestor] = useState(null);
    const openInvestor = Boolean(anchorElInvestor);
    const handleClickInvestor = (event) => {
        setAnchorElInvestor(event.currentTarget);
    }

    const [anchorElUser, setAnchorElUser] = useState(null);
    const openUser = Boolean(anchorElUser );
    const handleClickUser = (event) => {
        setAnchorElUser(event.currentTarget);
    }

    const [anchorElEmail, setAnchorElEmail] = useState(null);
    const openEmail = Boolean(anchorElEmail);
    const handleClickMenu = (event) => {
        setAnchorElEmail(event.currentTarget);
    }

    const [anchorElApp, setAnchorElApp] = useState(null); // App -->> Whatsapp
    const openApp = Boolean(anchorElApp);
    const handleClickApp = (event) => {
        setAnchorElApp(event.currentTarget);
    }

    const logout = () => {
        localStorage.removeItem('ACCESS_TOKEN');
        localStorage.removeItem('USER_ROLE');
        navigate('/')
    }

    // Functions to navigate to different menus
    const onManageColdLeadClick = () => {
        setAnchorElLead(null);
        setTimeout(() => {
            navigate('empty');
        }, 100);
        setTimeout(() => {
            navigate('lead-search?mode=COLD_LEADS');
        }, 120);
    }
    const onManageHotLeadClick = () => {
        setAnchorElLead(null);
        setTimeout(() => {
            navigate('empty');
        }, 100);
        setTimeout(() => {
            navigate('lead-search?mode=HOT_LEADS');
        }, 120);
    }
    const onAddLeadClick = () => {
        setAnchorElLead(null);
        setTimeout(() => {
            navigate('empty');
        }, 100);
        setTimeout(() => {
            navigate('lead-update');
        }, 120);
    }
    const onSearchLeadClick = () => {
        setAnchorElLead(null);
        setTimeout(() => {
            navigate('empty');
        }, 100);
        setTimeout(() => {
            navigate('lead-search');
        }, 120);
    }
    const onCreateProspectClick = () => {
        setAnchorElProspect(null);
        setTimeout(() => {
            navigate('empty');
        }, 100);
        setTimeout(() => {
            navigate('lead-search?mode=CREATE_PROSPECT');
        }, 120);
    }
    const onSearchProspectClick = () => {
        setAnchorElProspect(null);
        setTimeout(() => {
            navigate('empty');
        }, 100);
        setTimeout(() => {
            navigate('prospect-search');
        }, 120);
    }
    const onManageProspectClick = () => {
        setAnchorElProspect(null);
        setTimeout(() => {
            navigate('empty');
        }, 100);
        setTimeout(() => {
            navigate('prospect-search?mode=MANAGE_PROSPECT');
        }, 120);
    }
    const onSearchInvestorClick = () => {
        setAnchorElInvestor(null);
        setTimeout(() => {
            navigate('empty');
        }, 100);
        setTimeout(() => {
            navigate('investor-search');
        }, 120);
    }
    const onInvestorsToBeActivatedClick = () => {
        setAnchorElInvestor(null);
        setTimeout(() => {
            navigate('empty');
        }, 100);
        setTimeout(() => {
            navigate('investor-search?mode=INVESTORS_TOBE_ACTIVATED');
        }, 120);
    }
    const onUpdateInvestorClick = () => {
        setAnchorElInvestor(null);
        setTimeout(() => {
            navigate('investor-update');
        }, 100);
    }

    const onInvestorImportClick = () => {
        setAnchorElInvestor(null);
        setTimeout(() => {
            navigate('investor-import');
        }, 100);
    }

    const onSearchUserClick = () => {
        setAnchorElUser(null);
        setTimeout(() => {
            navigate('empty');
        }, 100);
        setTimeout(() => {
            navigate('user-search');
        }, 120);
    }

    const onCloseSearchUserClick = () => {
        setAnchorElUser(null);
        setTimeout(() => {
            //navigate('investor-update');
            navigate('user-search');
        }, 100);
    }

    const onCreateMailingClick = () => {
        setAnchorElEmail(null);
        setTimeout(() => {
            navigate('create-mailing');
        }, 100);
    }
    const onManageBCCRecipientsListClick = () => {
        setAnchorElEmail(null);
        setTimeout(() => {
            navigate('manage-recipient-lists');
        }, 100);
    } 
    const onManageEmailSettingsClick = () => {
        setAnchorElEmail(null);
        setTimeout(() => {
            navigate('email-settings');
        }, 100);
    }
    const onManageWhatsappSettingsClick = () => {
        setAnchorElApp(null);
        setTimeout(() => {
            navigate('whatsapp-settings')
        }, 100);
    }

    var prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            document.getElementById("navbar").style.opacity = 1;
            document.getElementById("navbar").style.zIndex = 100;
        } else {
            document.getElementById("navbar").style.opacity = 0;
            document.getElementById("navbar").style.zIndex = -1;
        }
        prevScrollpos = currentScrollPos;
    }

    return (
        <Box sx={{ position: 'fixed', display: 'block', zIndex: 100, transition: 'visibility 0s, opacity 0.5s linear' }} id='navbar' >
            <AppBar elevation={1} sx={{backgroundColor: 'black'}}>
                <Toolbar variant="dense">
                    <Grid container justifyContent={'space-between'} alignItems='center'>
                        <Grid item mt={1}>
                            <img src='/images/logo2.svg' height={48} alt='' />
                        </Grid>
                        <Grid item >
                            <Stack direction='row' spacing={2}>
                                <Button size='small' color={'inherit'} onClick={() => navigate('/home')}>&nbsp; HOME &nbsp; </Button>
                                <Button id='leads-button'
                                    size='small'
                                    color={'inherit'}
                                    onClick={handleClickLead}
                                    aria-controls={openLead ? 'leads-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={openLead ? 'true' : undefined}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >LEADS</Button>
                                {gIsAdminUser() && <Button id='prospects-button'
                                    size='small'
                                    color={'inherit'}
                                    onClick={handleClickProspect}
                                    aria-controls={openProspect ? 'prospects-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={openProspect ? 'true' : undefined}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >PROSPECTS</Button>}
                                <Button id='investors-button'
                                    size='small'
                                    color={'inherit'}
                                    onClick={handleClickInvestor}
                                    aria-controls={openInvestor ? 'investors-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={openInvestor ? 'true' : undefined}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >INVESTOR</Button>

                                { gIsAdminUser() && <Button id='mail-button'
                                    size='small'
                                    color={'inherit'}
                                    onClick={handleClickMenu}
                                    aria-controls={openEmail ? 'mail-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={openEmail ? 'true' : undefined}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >EMAIL</Button>}

                                { gIsSuperAdminUser() && <Button id='app-button'
                                    size='small'
                                    color={'inherit'}
                                    onClick={handleClickApp}
                                    aria-controls={openApp ? 'app-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={openApp ? 'true' : undefined}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >APP</Button>}

                                { gIsSuperAdminUser() && <Button id='user-button'
                                    size='small'
                                    color={'inherit'}
                                    onClick={handleClickUser}
                                    aria-controls={openEmail ? 'user-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={openEmail ? 'true' : undefined}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >USERS</Button>}
                                {/* <Button size='small' color={'inherit'} onClick={() => navigate('/admin')}>&nbsp; ADMIN &nbsp; </Button> */}
                                <Tooltip title="Logout">
                                    <LogoutIcon fontSize='large' color='white' onClick={logout} sx={{ cursor: 'pointer' }} />
                                </Tooltip>
                            </Stack>

                            <Menu
                                id='leads-menu'
                                open={openLead}
                                anchorEl={anchorElLead}
                                MenuListProps={{
                                    'aria-labelledby': 'leads-button'
                                }}
                                onClose={() => {setAnchorElLead(null)}}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                TransitionComponent={Fade}
                                disableScrollLock={true}
                            >
                                <MenuItem onClick={onManageColdLeadClick}>Manage Cold Leads</MenuItem>
                                {gIsAdminUser() && <MenuItem onClick={onManageHotLeadClick}>Manage Hot Leads</MenuItem>}
                                <MenuItem onClick={onAddLeadClick}>Add Leads</MenuItem>
                                <MenuItem onClick={onSearchLeadClick}>Search Leads</MenuItem>
                            </Menu>

                            <Menu
                                id='prospects-menu'
                                open={openProspect}
                                anchorEl={anchorElProspect}
                                MenuListProps={{
                                    'aria-labelledby': 'prospects-button'
                                }}
                                onClose={() => {setAnchorElProspect(null)}}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                TransitionComponent={Fade}
                                disableScrollLock={true}
                            >
                                <MenuItem onClick={onCreateProspectClick}>Create Prospects</MenuItem>
                                <MenuItem onClick={onManageProspectClick}>Manage Prospects</MenuItem>
                                <MenuItem onClick={onSearchProspectClick}>Search Prospects</MenuItem>
                            </Menu>

                            <Menu
                                id='users-menu'
                                open={openUser}
                                anchorEl={anchorElUser}
                                MenuListProps={{
                                    'aria-labelledby': 'users-button'
                                }}
                                onClose={() => {setAnchorElUser(null)}}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                TransitionComponent={Fade}
                                disableScrollLock={true}
                            >
                                {gIsSuperAdminUser() && <MenuItem onClick={onSearchUserClick}>Search User</MenuItem>}
                            </Menu>

                            <Menu
                                id='investors-menu'
                                open={openInvestor}
                                anchorEl={anchorElInvestor}
                                MenuListProps={{
                                    'aria-labelledby': 'investors-button'
                                }}
                                onClose={() => {setAnchorElInvestor(null)}}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                TransitionComponent={Fade}
                                disableScrollLock={true}
                            >
                                <MenuItem onClick={onInvestorsToBeActivatedClick}>Activate New Investors</MenuItem>
                                <MenuItem onClick={onUpdateInvestorClick}>Add Investor</MenuItem>
                                {gIsSuperAdminUser() && <MenuItem onClick={onInvestorImportClick}>Upload Investors</MenuItem> }
                                {gIsAdminUser() && <MenuItem onClick={onSearchInvestorClick}>Search Investor</MenuItem>}
                            </Menu>
                            
                            <Menu
                                id='menus-menu'
                                open={openEmail}
                                anchorEl={anchorElEmail}
                                MenuListProps={{
                                    'aria-labelledby': 'menus-button'
                                }}
                                onClose={() => {setAnchorElEmail(null)}}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                TransitionComponent={Fade}
                                disableScrollLock={true}
                            >
                                <MenuItem onClick={onCreateMailingClick}>Create Mailing</MenuItem>
                                <MenuItem onClick={onManageBCCRecipientsListClick}>Manage BCC Recipients List</MenuItem>
                                { gIsSuperAdminUser() && <MenuItem onClick={onManageEmailSettingsClick}>Manage  Email Settings</MenuItem>}
                            </Menu>

                            <Menu
                                id='app-menu'
                                open={openApp}
                                anchorEl={anchorElApp}
                                MenuListProps={{
                                    'aria-labelledby': 'app-button'
                                }}
                                onClose={() => {setAnchorElApp(null)}}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                TransitionComponent={Fade}
                                disableScrollLock={true}
                            >
                                { gIsSuperAdminUser() && <MenuItem onClick={onManageWhatsappSettingsClick}>Manage Whatsapp Settings</MenuItem>}
                            </Menu>
                                   

                            { gIsSuperAdminUser && <Menu
                                id='menus-menu'
                                // open={openEmail}
                                anchorEl={anchorElUser}
                                open={openUser}
                                MenuListProps={{
                                    'aria-labelledby': 'menus-button'
                                }}
                                onClose={ () => setAnchorElUser(null)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                TransitionComponent={Fade}
                                disableScrollLock={true}
                            >
                                <MenuItem onClick={onSearchUserClick}>Manage Users</MenuItem>
                            </Menu>}

                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default DesktopAppBar
