import React, { useEffect, useRef, useState } from 'react'
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, Radio, RadioGroup, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import FormInput from '../../../components/FormInput'
import FormSelect from '../../../components/FormSelect'
import PageHeader from '../../../components/PageHeader'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import AttachmentFileList from '../../../components/AttachmentFileList'
import UserAlert from '../../../components/UserAlert'
import { getAllPropertyTypes, getAllProvinces, getSourceByClass } from '../../../services/MasterDataService'
import { ATTACHMENT_CLASS, INVESTOR_STATUS, SOURCE_CLASS } from '../../../constants/AppConsts'
import { getInvestorById, saveInvestor } from '../../../services/InvestorService'
import PersonIcon from '@mui/icons-material/Person'
import { gIsAdminUser } from '../../../CommonUtils'
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { sendWhatsappMessage } from '../../../services/WhatsappSettingsService'

// Inline styles
const styles = {
    gridItemPadding: {
        px: 0.1,
        py: 0.5
    },
    gridItemPaddingDouble: {
        px: 0.1,
        py: 1.0
    }
};

const InvestorUpdate = (props) => {
    let { investorId: invId } = props;

    const iconInvestor = <PersonIcon />
    const iconFile = <AttachFileOutlinedIcon />
    const userAlert = useRef(null);
    const [investorId, setInvestorId] = useState(invId);

    const initialValues = {
        sourceCode: 'MANUAL_ENTRY',
        searchName: '',
        phoneNumber: '',
        priceRangeMinimum: '',
        priceRangeMaximum: '',
        id: '',
        statusCode: '200',
        status: '',
        email: '',
        investorRemark: '',
        rdVastgoedRemark: '',
        propertyTypeCodes: [],
        provinceCodes: [],
        alreadyInvesting: '',
        wantsLowYieldRental: '',
        wantsForeignInvestments: '',
        countriesToInvestIn: '',
    }

    const statusItems = [
        { id: '100', name: 'Initial' },
        { id: '200', name: 'Active' },
        { id: '900', name: 'Inactive' },
    ]

    const [values, setValues] = useState(initialValues)
    const [errors, setErrors] = useState([]);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [sources, setSources] = useState([]);
    const [propertyTypeCodesCheckAll, setPropertyTypeCodesCheckAll] = useState(false);
    const [provinceCodesCheckAll, setProvinceCodesCheckAll] = useState(false);
    const [shouldActivateInvestor, setShouldActivateInvestor] = useState(false);
    const [pageHeader, setPageHeader] = useState('Add / Modify Investor');
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [activateInvestor, setActivateInvestor] = useState(false);

    const _getAllPropertyTypes = async () => {
        setPropertyTypes(await getAllPropertyTypes());
    }
    useEffect(() => {
        _getAllPropertyTypes();
    }, [])

    const _getAllProvinces = async () => {
        setProvinces(await getAllProvinces());
    }
    useEffect(() => {
        _getAllProvinces();
    }, [])

    const getSourcess = async () => {
        let sourceList = await getSourceByClass(SOURCE_CLASS.INVESTOR);
        setSources(
            sourceList?.map((source) => {
                return ({
                    id: source.code,
                    name: `${source.description}`,
                });
            })
        );
    }
    useEffect(() => {
        getSourcess();
    }, [])

    const handleInputChanage = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    useEffect(() => {
        setPropertyTypeCodesCheckAll(arrayEqualChecker(values.propertyTypeCodes, propertyTypes.map(propT => propT.code)))
    }, [propertyTypes])

    useEffect(() => {
        setProvinceCodesCheckAll(arrayEqualChecker(values.provinceCodes, provinces.map(prov => prov.code)))
    }, [provinces])

    const handleCheckboxChange = (e) => {
        const { name, value, checked } = e.target;
        if (checked) {
            if (!values[name]) values[name] = [];
            values[name].push(value)
        }
        else {
            const index = values[name].indexOf(value);
            if (index > -1) { // only splice array when item is found
                values[name].splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        setValues({
            ...values,
        });
    };

    let arrayEqualChecker = (arr, target) => target.every(v => arr?.includes(v));

    const handlePropertyTypeCodesCheckboxChange = (e) => {
        handleCheckboxChange(e);

        setPropertyTypeCodesCheckAll(arrayEqualChecker(values.propertyTypeCodes, propertyTypes.map(propT => propT.code)))
        setValues({
            ...values
        })
    }

    const handleProvinceCodesCheckboxChange = (e) => {
        handleCheckboxChange(e);

        setProvinceCodesCheckAll(arrayEqualChecker(values.provinceCodes, provinces.map(propT => propT.code)))
        setValues({
            ...values
        })
    }

    const getInvestorDetails = async () => {
        let response = await getInvestorById(investorId);
        if (response.status.code == INVESTOR_STATUS.INITIAL) {
            setPageHeader('Activate Investor')
            setShouldActivateInvestor(true)
        }

        let tempValues = {
            sourceCode: response.source.code,
            searchName: response.searchName,
            phoneNumber: response.phoneNumber,
            priceRangeMinimum: response.priceRangeMinimum,
            priceRangeMaximum: response.priceRangeMaximum,
            id: response.id,
            statusCode: response.status.code,
            status: response.status.description,
            email: response.email,
            investorRemark: response.investorRemark,
            rdVastgoedRemark: response.rdVastgoedRemark,
            propertyTypeCodes: response.investorPropertyTypes?.map(ipt => ipt.propertyType.code),
            provinceCodes: response.investorProvinces?.map(ip => ip.province.code),
            alreadyInvesting: response.alreadyInvesting,
            wantsLowYieldRental: response.wantsLowYieldRental,
            wantsForeignInvestments: response.wantsForeignInvestments,
            countriesToInvestIn: response.countriesToInvestIn,
            //propertyTypeCodesCheckAll: arrayEqualChecker(values.propertyTypeCodes, propertyTypes.map(propT => propT.code))
        }
        setValues({
            ...values,
            ...tempValues
        });
    }

    useEffect(() => {
        if (investorId) getInvestorDetails();
    }, [investorId])

    const validateInvestor = () => {
        userAlert.current.clearAll()
        let temp = {}
        temp.sourceCode = values.sourceCode !== '' ? '' : 'Source is required'
        temp.searchName = values.searchName !== '' ? '' : 'Search Name is required'
        temp.priceRangeMinimum = (!values.priceRangeMinimum || (/^[0-9.]{1,15}$/).test(values.priceRangeMinimum)) ? '' : 'Invalid price range (min)'
        temp.priceRangeMaximum = (!values.priceRangeMaximum || (/^[0-9.]{1,15}$/).test(values.priceRangeMaximum)) ? '' : 'Invalid price range (max)'
        temp.statusCode = values.statusCode ? '' : 'Status is required'
        temp.phoneNumber = (!values.phoneNumber || (/^[\+]?[00]?[0-9]{2}[\-]?[0-9]{8,12}$/).test(values.phoneNumber)) ? '' : 'A valid phone number is required (minimum 10 digits)'
        temp.email = ((/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,2}$/).test(values.email)) ? '' : 'A valid email is required'
        temp.propertyType = values.propertyTypeCodes?.length > 0 ? '' : 'Please select at least one property type'
        temp.province = values.provinceCodes?.length > 0 ? '' : 'Please select at least one province'
        setErrors({ ...temp })

        return (Object.values(temp).every(x => x === "")) ? true : false;
    }

    const _saveInvestor = async (activateInvestor) => {
        userAlert.current.clearAll()
        if (validateInvestor()) {
            if (activateInvestor) {
                values.statusCode = INVESTOR_STATUS.ACTIVE
                setActivateInvestor(false)
            }
            let response = await saveInvestor(
                values
            );

            if (!response.error) {
                setInvestorId(response.data.id)
                setValues(
                    { ...values, id: response.data.id }
                )

                if (response.status === 201) {
                    userAlert.current.setInfo('Investor saved successfully.')
                } else if (response.status === 200) {
                    userAlert.current.setInfo('Investor saved successfully, and Welcome Message sent')
                }

            } else if (response.error.code == 456) {
                userAlert.current.setInfo('Investor saved successfully, could not send Welcome Message.')
            } else {
                userAlert.current.setError(`Saving investor failed.`)
            }
        }
    }

    const confirmInverstorActivation = () => {
        setActivateInvestor(true)
        setIsConfirmDialogOpen(true);
    }

    const toggleAllPropertyTypes = (e) => {
        if (!e.target.checked) {
            values.propertyTypeCodes = [];
            setPropertyTypeCodesCheckAll(false);
        }
        else {
            values.propertyTypeCodes = propertyTypes.map(propT => propT.code);
            setPropertyTypeCodesCheckAll(true);
        }
        setValues({ ...values })
    }

    const toggleAllProvinceCodes = (e) => {
        if (!e.target.checked) {
            values.provinceCodes = [];
            setProvinceCodesCheckAll(false);
        }
        else {
            values.provinceCodes = provinces.map(prov => prov.code);
            setProvinceCodesCheckAll(true);
        }
        setValues({ ...values })
    }

    const handleConfirm = async () => {
        await _saveInvestor(activateInvestor)
        setShouldActivateInvestor(false)
        setIsConfirmDialogOpen(false)
    }

    const handleCancel = () => {
        setIsConfirmDialogOpen(false)
    }

    const isValidPhoneNumber = () => {
        let validFormat = /^[1-9]\d+$/g;
        try {
            let isValidNumber = values.phoneNumber.match(validFormat) !== null;
            return isValidNumber;
        } catch (e) {
            return true;
        }
    }
    const ConfirmQuestion = isValidPhoneNumber() ? 'This investor will now become active, are you sure you have completed the profile as much as possible?'
        : 'ATTENTION: The phone number is not WhatsApp compliant. Press NO if you want to correct it first, press YES to ignore and activate the investor.';


    return (
        <>
            <UserAlert ref={userAlert} />

            <PageHeader title={pageHeader} icon={iconInvestor} />
            <form autoComplete='off' noValidate id='frmRegisterVendor'>
                <Box mt={0.0} bgcolor={'background.paper'}>
                    <Grid container>

                        {/* column 1 */}
                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Search Name' value={values.searchName} name="searchName" error={errors.searchName} required={true} />
                                </Grid>
                                {/* <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Phone No.' value={values.phoneNumber} name='phoneNumber' error={errors.phoneNumber} />
                                </Grid> */}
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={10} sx={styles.gridItemPadding}>
                                        <FormInput onChange={handleInputChanage} label='Phone No.' name="phoneNumber" value={values.phoneNumber} error={errors.phoneNumber} />
                                    </Grid>
                                    <Grid item xs={2} sx={styles.gridItemPadding}>
                                        <a href={`tel:${values.phoneNumber}`}>
                                            <IconButton sx={{ float: 'right' }} disabled={values.phoneNumber ? false : true} >
                                                <PhoneForwardedOutlinedIcon color="success" />
                                            </IconButton>
                                        </a>
                                    </Grid>
                                </Grid>
                                <Grid container sx={styles.gridItemPadding} alignItems={'center'}>
                                    <Grid item xs={7}>
                                        <Typography>Already investing in real estate?</Typography>
                                    </Grid>
                                    <Grid item xs={5} alignContent={'end'}>
                                        <FormControl>
                                            <RadioGroup name="alreadyInvesting" row value={values.alreadyInvesting} onChange={handleInputChanage}>
                                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput
                                        name="investorRemark"
                                        value={values.investorRemark}
                                        label="Investor Remark"
                                        multiline={true}
                                        rows={3}
                                        maxRows={4}
                                        onChange={handleInputChanage}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>

                        {/* column 2 */}
                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <Grid container>
                                        <Grid item xs={6} sx={{ paddingRight: 0.5 }}>
                                            <FormInput onChange={handleInputChanage} label='Price Range (Min)' value={`${values.priceRangeMinimum}`} name='priceRangeMinimum' error={errors.priceRangeMinimum} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormInput onChange={handleInputChanage} label='Price Range (Max)' value={`${values.priceRangeMaximum}`} name='priceRangeMaximum' error={errors.priceRangeMaximum} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Email' name="email" value={values.email} error={errors.email} required={true} />
                                </Grid>
                                <Grid container sx={styles.gridItemPadding} alignItems={'center'}>
                                    <Grid item xs={7}>
                                        <Typography>Interested in low yield rental?</Typography>
                                    </Grid>
                                    <Grid item xs={5} alignContent={'end'}>
                                        <FormControl>
                                            <RadioGroup name="wantsLowYieldRental" row value={values.wantsLowYieldRental} onChange={handleInputChanage}>
                                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPaddingDouble}>
                                    <FormInput
                                        name="rdVastgoedRemark"
                                        value={values.rdVastgoedRemark}
                                        label="RD Vastgoed Remark"
                                        multiline={true}
                                        rows={3}
                                        maxRows={4}
                                        onChange={handleInputChanage}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* column 3 */}
                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid item flexGrow={1} xs={6} sx={styles.gridItemPadding}>
                                    <FormSelect
                                        name="statusCode"
                                        label="Status"
                                        value={values.statusCode || ''}
                                        onChange={handleInputChanage}
                                        items={statusItems}
                                        error={errors.statusCode}
                                    />
                                </Grid>
                                <Grid item flexGrow={1} xs={6} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Investor Id' name="id" value={values.id} error={errors.id} disabled={true} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormSelect
                                        name="sourceCode"
                                        label="Source *"
                                        value={values.sourceCode || ''}
                                        onChange={handleInputChanage}
                                        items={sources}
                                        error={errors.sourceCode}
                                    />
                                </Grid>
                                <Grid container sx={styles.gridItemPadding} alignItems={'center'}>
                                    <Grid item xs={7}>
                                        <Typography>Interested in foreign real estate?</Typography>
                                    </Grid>
                                    <Grid item xs={5} alignContent={'end'}>
                                        <FormControl>
                                            <RadioGroup name="wantsForeignInvestments" row value={values.wantsForeignInvestments} onChange={handleInputChanage}>
                                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                <FormControlLabel value={false} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPaddingDouble}>
                                    <FormInput
                                        name="countriesToInvestIn"
                                        value={values.countriesToInvestIn}
                                        label="Countries To Invest In"
                                        multiline={true}
                                        rows={3}
                                        maxRows={4}
                                        onChange={handleInputChanage}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Property types */}
                    <Box m={1} mt={3} bgcolor={'background.default'}>
                        <Grid container>
                            <Grid item xs={12} px={1} sx={{ backgroundColor: 'grey.300' }}>
                                <Typography>Property Types:</Typography>
                            </Grid>
                            <Grid item xs={12} px={1} sx={{}}>
                                {errors.propertyType && <FormHelperText sx={{ color: 'error.main' }}>{errors.propertyType}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} md={12} px={1}>
                                <FormControlLabel control={<Checkbox onChange={toggleAllPropertyTypes} checked={propertyTypeCodesCheckAll} value={propertyTypeCodesCheckAll} />} label="Select All" />
                            </Grid>
                            {
                                propertyTypes?.map(propertyType => {
                                    let shouldCheck = values.propertyTypeCodes ? values.propertyTypeCodes?.includes(propertyType.code) : false;

                                    return (<Grid item xs={6} md={2} px={1} key={propertyType.did}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={shouldCheck} name='propertyTypeCodes'
                                                onChange={handlePropertyTypeCodesCheckboxChange} value={propertyType.code} />} label={propertyType.name}
                                            />
                                        </FormGroup>
                                    </Grid>)
                                })
                            }
                        </Grid>
                    </Box>

                    {/* Province codes */}
                    <Box m={1} mt={3} bgcolor={'background.default'}>
                        <Grid container>
                            <Grid item xs={12} px={1} sx={{ backgroundColor: 'grey.300' }}>
                                <Typography>Provinces:</Typography>
                            </Grid>
                            <Grid item xs={12} px={1} sx={{}}>
                                {errors.province && <FormHelperText sx={{ color: 'error.main' }}>{errors.province}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} md={12} px={1}>
                                <FormControlLabel control={<Checkbox onChange={toggleAllProvinceCodes} checked={provinceCodesCheckAll} value={provinceCodesCheckAll} />} label="Select All" />
                            </Grid>
                            {
                                provinces?.map(province => {
                                    let shouldCheck = values.provinceCodes ? values.provinceCodes?.includes(province.code) : false;

                                    return (<Grid item xs={6} md={2} px={1} key={province.did}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={shouldCheck} name='provinceCodes'
                                                onChange={handleProvinceCodesCheckboxChange} value={province.code} />} label={province.name}
                                            />
                                        </FormGroup>
                                    </Grid>)
                                })
                            }
                        </Grid>
                    </Box>

                    <Grid container justifyContent={'flex-end'}>
                        <Grid item>
                            <Box p={1} width={'100%'}>
                                {shouldActivateInvestor &&
                                    <Button size='small' type='button' variant="contained" sx={{ float: 'right', marginLeft: 1 }} onClick={() => confirmInverstorActivation()}>ACTIVATE</Button>
                                }
                                {values.statusCode !== INVESTOR_STATUS.INITIAL &&
                                    <Button size='small' type='button' variant="contained" sx={{ float: 'right', marginLeft: 1 }} onClick={() => _saveInvestor(false)}>SAVE</Button>
                                }
                                {values.statusCode === INVESTOR_STATUS.INITIAL &&
                                    <Tooltip title='Save'><span onClick={() => _saveInvestor(false)}><SaveOutlinedIcon fontSize={'large'} color='primary' sx={{ cursor: 'pointer' }} /></span></Tooltip>
                                    // <Button size='small' type='button' variant="contained" sx={{ float: 'right', marginLeft: 1 }} onClick={() => _saveInvestor(false)}>S</Button>
                                }
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{ height: 10 }} ></Box>
                </Box>


            </form>

            {/* file attachments */}
            {investorId && gIsAdminUser() &&
                <>
                    <Box mt={3}>
                        <PageHeader title='Files' icon={iconFile} />
                    </Box>
                    <Box p={1} bgcolor={'background.paper'} >
                        <Grid container>
                            <Grid item flex={1}>
                                <AttachmentFileList ownerClass={ATTACHMENT_CLASS.INVESTOR} ownerId={investorId} />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ height: 50 }}>&nbsp;</Box>
                </>
            }

            {/* confirmation dialog */}
            <ConfirmationDialog
                isOpen={isConfirmDialogOpen}
                isValidPhoneNumber={isValidPhoneNumber()}
                title='Confirm'
                question={ConfirmQuestion}
                confirmButtonText='Yes'
                cancelButtonText='No'
                handleConfirm={handleConfirm}
                handleCancel={handleCancel} />
        </>
    )
}

export default InvestorUpdate