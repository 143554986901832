import React, { useRef, useState, useEffect } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import FormInput from "../../components/FormInput";
import { useNavigate, useLocation } from "react-router-dom";
import { apiClient } from "../../services/axios";
import useAuth from "../../hooks/useAuth";

const Login = () => {
    const { auth, setAuth } = useAuth('');

    const navigate = useNavigate();
    const location = useLocation();
    const from = !(location?.pathname === '/' || location?.pathname === '/login') ? location?.pathname : "/home";
    //const userRef = useRef()
    const errRef = useRef()

    const [user, setUser] = useState('')
    const [pwd, setPwd] = useState('')
    const [errMsg, setErrMsg] = useState()

    const handleSubmit = async (e) => {
        e.preventDefault()
        localStorage.removeItem('ACCESS_TOKEN');

        apiClient.post('/rest/authenticate',
            JSON.stringify({ username: user, password: pwd }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            },
        ).then(function (response) {
            //setAuth( response.headers.token )
            // setUser('');
            // setPwd('');

            // TODO: temporaly saving in localStorage
            localStorage.setItem('ACCESS_TOKEN', response.headers.token);
            localStorage.setItem('USER_ROLE', response.data.userRole);
            navigate(from, { replace: true })
        }).catch(function (err) {
            setErrMsg('Invalid username or password.')
            errRef.current.focus()
        });
    }

    return (
        <Grid container alignItems={'center'} height="calc(100vh - 120px)">
            <Grid item xs={1} sm={2} md={3} lg={4}></Grid>

            <Grid item xs={10} sm={8} md={6} lg={4}>
                <Paper sx={{ backgroundColor: 'grey.100' }}>
                    <form onSubmit={handleSubmit}>
                        <Grid p={3}
                            container
                            spacing={2}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Grid item xs={12}>
                                <Typography ref={errRef} color={'error'}>{errMsg}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="username"
                                    label="Username"
                                    //ref={userRef}
                                    autoComplete="off"
                                    value={user}
                                    required={true}
                                    onChange={(e) => setUser(e.target.value)}
                                >
                                </FormInput>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    name="password"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    value={pwd}
                                    required={true}
                                    onChange={(e) => setPwd(e.target.value)}
                                >
                                </FormInput>
                            </Grid>
                            <Grid item xs={12} alignItems={'end'}>
                                <Button type='submit' variant="contained"> Login </Button> {/* onClick={() => navigate('/admin')} */}
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>

            <Grid item xs={1} sm={2} md={3} lg={4}></Grid>

        </Grid>

    );
}

export default Login