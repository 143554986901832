import { apiClientPrivate } from './axios';
import { gGetError } from '../CommonUtils';
import { USER_ROLES, USER_STATUS } from '../constants/AppConsts';

export async function getUsers(userObj) {
    try {
        const userRole = userObj.userRole == '' ? null : userObj.userRole
        const userStatus = userObj.userStatus == '' ? null : userObj.userStatus

        let response = await apiClientPrivate.post(`/rest/user/auf/list?pn=${userObj.pn}&ipp=${userObj.ipp}`,
            {
                did: userObj.did,
                name: userObj.name,
                mobilePhone: userObj.mobilePhone,
                email: userObj.email,
                userRole: userRole,
                userStatus: userStatus,
                status: {
                    code: userObj.statusCode
                },
            }
        );
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function saveUser(userObj) {
    try {
        const userRole = userObj.userRole == '' ? null : userObj.userRole
        const userStatus = userObj.userStatus == '' ? null : userObj.userStatus

        let response = await apiClientPrivate.post(`/rest/user/auf/create`,
            {
                did: userObj.did,
                name: userObj.name,
                mobilePhone: userObj.mobilePhone,
                email: userObj.email,
                userRole: userRole,
                userStatus: userStatus,
                password: userObj.password,
                status: {
                    code: userObj.statusCode
                },
            }
        )
        return response.data

    } catch (err) {
        return gGetError(err)
    }
}

export async function getUserById(userId) {
    try {
        let response = await apiClientPrivate.get(`/rest/user/auf/${userId}`);
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function getNoOfSuperUsers() {
    try {
        let response = await apiClientPrivate.post(`/rest/user/auf/list?pn=1&ipp=100`,
            {
                userRole: USER_ROLES.SUPER_ADMIN.value,
                userStatus: USER_STATUS.ACTIVE.value
            }
        );
        
        return response.data.content.length;
    } catch (err) {
        return 1
    }
}



