import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import UserAlert from '../../../components/UserAlert';
import { ACTION_CATEGORY, ACTION_CLASS, DUTCH_LOCALE } from '../../../constants/AppConsts';
import { getActionsByClassAndCategory } from '../../../services/ActionService';
import { getLeads, getLeadsExports } from '../../../services/LeadService';
import { getActionsByClass, getAllProvinces } from '../../../services/MasterDataService';
import LeadDetails from './LeadDetails';
import SearchLeadForm from './LeadSearchForm';
import LeadSearchTable from './LeadSearchTable';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import LeadFindInvestors from './LeadFindInvestors';
import Breadcrumb from '../../../components/Breadcrumb';

const LeadSearch = () => {
    const userAlert = useRef(null)
    const [searchParams] = useSearchParams();
    const icon = <ContentPasteIcon />
    const navigate = useNavigate();

    const initialValues = {
        id: '',
        searchName: '',
        nextActions: [],
        nextActionDateFrom: '',
        nextActionDateTo: '',
        priceDesired: '',
        sourceAdId: '',
        street: '',
        houseNumber: '',
        postalCode: '',
        city: '',
        provinceCode: '',
        leadId: '',
        phoneNumber: '',
        email: '',
        details: '',
        keyword: '',
    }
    const [loadData, setLoadData] = useState(false);
    const [values, setValues] = useState(initialValues)
    const [errors, setErrors] = useState([]);
    const [provinces, setProvinces] = useState([])
    const [actions, setActions] = useState([])
    const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false)
    const [isFindInvestorsDialogOpen, setIsFindInvestorsDialogOpen] = useState(false)
    const [leadId, setLeadId] = useState('')
    const [searchCount, setSearchCount] = useState(0)
    const [showBreadcrum, setShowBreadcrum] = useState(false)

    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 1,
        pageSize: 100
    })

    const openDetailsModal = () => {
        setLeadId('')
        setIsDetailsDialogOpen(true)
    }

    const handleInputChanage = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleMultiSelectChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: typeof value === 'string' ? value.split(',') : value,
        });
    };

    const clearLeadForm = () => {
        setValues(initialValues);
        let temp = {};
        setErrors({ ...temp })
    }

    const mapToFlatStructure = (responseContent) => {
        return responseContent.map(row => {
            return ({
                id: row.id,
                searchName: row.searchName,
                nextAction: row.nextActionSeries ? `${row.nextActionSeries?.action.code} - ${row.nextActionSeries?.action.description}` : '',
                nextActionDate: row.nextActionSeries?.date,
                street: row.propertyAddress.street,
                houseNumber: row.propertyAddress.houseNumber,
                city: row.propertyAddress.city,
                priceDesired: row.priceDesired?.toLocaleString(DUTCH_LOCALE),
                phoneNumber: row.phoneNumber,
                email: row.email,
                leadId: row.id,
            });
        })
    }

    const getProvinces = async () => {
        let provinceList = await getAllProvinces();
        setProvinces(
            provinceList.map((province) => {
                return ({
                    id: province.code,
                    name: `${province.code} - ${province.name}`,
                });
            })
        );
    }
    useEffect(() => {
        getProvinces();
    }, [])

    const getActions = async (actionClass) => {
        let actionList = await getActionsByClass(actionClass);
        setActions(
            actionList.map((action) => {
                return ({
                    id: action.code,
                    name: `${action.code} - ${action.description}`,
                });
            })
        );
    }
    useEffect(() => {
        getActions(ACTION_CLASS.LEAD);
    }, [])

    const searchLeads = async () => {
        setLoadData(true);
        userAlert.current.clearAll();

        let temp = {};
        temp.phoneNumber = (values.phoneNumber === '' || (/^[\+]?[00]?[0-9]{2}[\-]?[0-9]{8,12}$/).test(values.phoneNumber)) ? '' : 'Phone noumber has to be valid (minimum 10 digits)'
        temp.email = (values.email === '' || (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,2}$/).test(values.email)) ? '' : 'A valid email address is required'
        setErrors({ ...temp })

        if (Object.values(temp).every(x => x === "")) {
            setPageState(old => ({ ...old, isLoading: true }));

            let response = await getLeads({
                ...values,
                pn: pageState.page,
                ipp: pageState.pageSize,

            });

            setPageState(old => ({ ...old, isLoading: false, data: mapToFlatStructure(response.content), total: response.totalElements }));
        }
    }

    const exportLeads = async () => {
        let response = await getLeadsExports({
            ...values,
        });

        if (response) {
            let a = document.createElement("A");
            a.setAttribute("href", 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response);
            a.setAttribute('download', 'leads-export.xlsx');
            document.body.appendChild(a);
            a.click();
        }
    }

    const exportLeadsCSV = async () => {
        let response = await getLeadsExports({
            ...values,
        }, "csv");

        if (response) {
            let a = document.createElement("A");
            a.setAttribute("href", 'data:text/csv;charset=UTF-8,' + response);
            a.setAttribute('download', 'leads-export.csv');
            document.body.appendChild(a);
            a.click();
        }
    }

    useEffect(() => {
        if (loadData) searchLeads();
    }, [loadData, pageState.pageSize, pageState.page, searchCount])

    const showLeadDetails = (leadId) => {
        setLeadId(leadId)
        setIsDetailsDialogOpen(true)
    }

    const showFindInvestors = (data) => {
        setLeadId(data.value)
        setIsFindInvestorsDialogOpen(true)
    }

    const gotoCreateProspect = (data) => {
        navigate(`/prospect-create?leadId=${data.value}`)
    }

    const loadColdLeads = async () => {
        let response = await getActionsByClassAndCategory(ACTION_CLASS.LEAD, ACTION_CATEGORY.LEAD_INITIAL)
        values.nextActions = response.map(act => act.code)
        values.nextActionDateTo = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
        setValues({ ...values })
        searchLeads();
    }

    const loadHotLeads = async () => {
        let rescat2 = await getActionsByClassAndCategory(ACTION_CLASS.LEAD, ACTION_CATEGORY.LEAD_READY_FOR_SALE)
        let rescat3 = await getActionsByClassAndCategory(ACTION_CLASS.LEAD, ACTION_CATEGORY.LEAD_AWAIT_MAILING_RESULT)
        values.nextActions = rescat2.map(act => act.code).concat(rescat3.map(act => act.code));
        values.nextActionDateTo = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
        setValues({ ...values })
        searchLeads();
    }

    const loadCreateProspect = async () => {
        let response = await getActionsByClassAndCategory(ACTION_CLASS.LEAD, ACTION_CATEGORY.LEAD_AWAIT_MAILING_RESULT)
        values.nextActions = response.map(act => act.code)
        setValues({ ...values })
        searchLeads();
    }

    useEffect(() => {
        if (searchParams.get("mode") === "COLD_LEADS") {
            loadColdLeads()
        } else if (searchParams.get("mode") === "HOT_LEADS") {
            loadHotLeads()
        } else if (searchParams.get("mode") === "CREATE_PROSPECT") {
            setShowBreadcrum(true)
            loadCreateProspect()
        }
    }, [])

    const closeLeadDetailsDialog = () => {
        setSearchCount(cnt => ++cnt)
        setIsDetailsDialogOpen(false)
    }

    return (
        <section>
            {showBreadcrum && <Breadcrumb breadcrumbs={['', 'Create Prospect - Step 1']} />}
            <PageHeader title='Search Leads' icon={icon} />
            <SearchLeadForm userAlert={<UserAlert ref={userAlert} />} values={values} errors={errors} provinces={provinces}
                actions={actions} handleInputChanage={handleInputChanage} handleMultiSelectChange={handleMultiSelectChange} clearLeadForm={clearLeadForm}
                searchLeads={searchLeads} exportLeads={exportLeads} exportLeadsCSV={exportLeadsCSV} openDetailsModal={openDetailsModal}
            />
            <Box mt={1} />
            <PageHeader title='Search Leads - Results' icon={icon} /*rightSideComponent={<ShowExtendedResults />}*/ />
            <LeadSearchTable pageState={pageState} setPageState={setPageState} showLeadDetails={showLeadDetails} showFindInvestors={showFindInvestors}
                gotoCreateProspect={gotoCreateProspect} />
            <LeadDetails leadId={leadId} isDetailsDialogOpen={isDetailsDialogOpen} closeLeadDetailsDialog={closeLeadDetailsDialog} />
            <LeadFindInvestors isFindInvestorsDialogOpen={isFindInvestorsDialogOpen} setIsFindInvestorsDialogOpen={setIsFindInvestorsDialogOpen} leadId={leadId}
                showLeadDetails={showLeadDetails} />
        </section>
    )
}

export default LeadSearch