import { Dialog, DialogContent } from '@mui/material';
import User from './User';
import CloseIcon from '@mui/icons-material/Close';

const UserDetailsForm = (props) => {
    let {user, isUserDialogOpen, closeUserDialog} = props;

    return (
        <Dialog open={isUserDialogOpen} onClose={closeUserDialog} maxWidth={'lg'} >
            <DialogContent sx={{width:'90vw', maxWidth:'lg'}} >
                <span onClick={closeUserDialog} ><CloseIcon sx={{float:'right', cursor:'pointer'}}/></span>
                <User user={user} />
            </DialogContent>
        </Dialog>
    )
}

export default UserDetailsForm