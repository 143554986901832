import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HotLeads from '../hot-leads/HotLeads';

const LeadFindInvestors = (props) => {
    let {leadId, isFindInvestorsDialogOpen, setIsFindInvestorsDialogOpen, showLeadDetails, showFindInvestors} = props

    return (
        <Dialog open={isFindInvestorsDialogOpen} onClose={() => setIsFindInvestorsDialogOpen(false)} maxWidth={'lg'} >
            <DialogContent sx={{width:'90vw', maxWidth:'lg'}} >
                <span onClick={()=> setIsFindInvestorsDialogOpen(false)} ><CloseIcon sx={{float:'right', cursor:'pointer'}}/></span>
                <HotLeads leadId={leadId} showLeadDetails={showLeadDetails} showFindInvestors={showFindInvestors} />
            </DialogContent>
        </Dialog>
    )
}

export default LeadFindInvestors