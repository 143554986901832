import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined'
import DomainIcon from '@mui/icons-material/Domain'
import { Button, Grid, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import { gPrint } from '../../../CommonUtils'
import ActionSeries from '../../../components/ActionSeries'
import AttachmentFileList from '../../../components/AttachmentFileList'
import FormInput from '../../../components/FormInput'
import FormSelect from '../../../components/FormSelect'
import PageHeader from '../../../components/PageHeader'
import UserAlert from '../../../components/UserAlert'
import { ACTION_CLASS, ATTACHMENT_CLASS, SOURCE_CLASS } from '../../../constants/AppConsts'
import { getAddressByPostalCodeAndHouseNumber } from '../../../services/AddressService'
import { getLeadById, saveLead } from '../../../services/LeadService'
import { getAllPropertyTypes, getAllSalesStarts, getSourceByClass } from '../../../services/MasterDataService'
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';

// Inline styles
const styles = {
    gridItemPadding: {
        px: 0.1,
        py: 0.5
    }
};

const LeadUpdate = (props) => {
    const userAlert = useRef(null)

    const initialValues = {
        sourceCode: 'MANUAL_ENTRY',
        searchName: '',
        streetHouseNumberSuffix: '',
        postalCodeCity: '',
        provinceCode: '',
        province: '',
        sellerRemark: '',
        priceDesired: '',
        priceWLFrom: '',
        priceWLTo: '',
        phoneNumber: '',
        email: '',
        id: '',
        rdVastgoedRemark: '',
        postalCode: '',
        houseNumber: '',
        houseNumberSuffix: '',
        propertyTypeCode: '',
        wozPropertyValue: '',
        floorAreaSqMeters: '',
        sourceAdId: '',
        street: '',
        city: '',
        salesStartCode: '',
        isOwnProperty: '',
    }

    const isOwnPropertyItems = [
        { id: true, name: 'Yes' },
        { id: false, name: 'No' }
    ]

    const [values, setValues] = useState(initialValues)
    const [errors, setErrors] = useState([]);
    const [sources, setSources] = useState([]);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [salesStarts, setSalesStarts] = useState([]);
    const [leadId, setLeadId] = useState(props.leadId);

    const handleInputChanage = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const clearAddLeadForm = () => {
        userAlert.current.clearAll()
        setValues(initialValues);
        let temp = {};
        setErrors({ ...temp })
    }

    const getSourcess = async () => {
        let sourceList = await getSourceByClass(SOURCE_CLASS.LEAD);
        setSources(
            sourceList.map((source) => {
                return ({
                    id: source.code,
                    name: `${source.description}`,
                });
            })
        );
    }
    useEffect(() => {
        getSourcess();
    }, [])

    const getPropertyTypes = async () => {
        let propertyTypeList = await getAllPropertyTypes();
        setPropertyTypes(
            propertyTypeList.map((propertyType) => {
                return ({
                    id: propertyType.code,
                    name: `${propertyType.name}`,
                });
            })
        );
    }
    useEffect(() => {
        getPropertyTypes();
    }, [])

    const _getSalesStarts = async () => {
        let salesStartList = await getAllSalesStarts();
        setSalesStarts(
            salesStartList.map((salesStart) => {
                return ({
                    id: salesStart.code,
                    name: `${salesStart.name}`,
                });
            })
        );
    }
    useEffect(() => {
        _getSalesStarts();
    }, [])

    const validateLead = () => {
        userAlert.current.clearAll()
        let temp = {}
        temp.sourceCode = values.sourceCode !== '' ? '' : 'Source is required'
        temp.searchName = values.searchName !== '' ? '' : 'Search Name is required'
        temp.priceDesired = (!values.priceDesired || (/^[0-9.]{1,15}$/).test(values.priceDesired)) ? '' : 'Invalid desired price'
        temp.priceWLFrom = (!values.priceWLFrom || (/^[0-9.]{1,15}$/).test(values.priceWLFrom)) ? '' : 'Invalid WL price (min)'
        temp.priceWLTo = (!values.priceWLTo || (/^[0-9.]{1,15}$/).test(values.priceWLTo)) ? '' : 'Invalid WL price (max)'
        temp.phoneNumber = (!values.phoneNumber || (/^[\+]?[00]?[0-9]{2}[\-]?[0-9]{8,12}$/).test(values.phoneNumber)) ? '' : 'A valid phone number is required (minimum 10 digits)'
        temp.email = (!values.email || (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,2}$/).test(values.email)) ? '' : 'A valid email is required'
        temp.postalCode = (!values.postalCode || (/^[0-9]{4}[a-zA-Z]{2}$/).test(values.postalCode)) ? '' : 'Invalid postal code'
        temp.houseNumber = (!values.houseNumber || (/^[0-9]{1,6}$/).test(values.houseNumber)) ? '' : 'Invalid postal code'
        temp.wozPropertyValue = (!values.wozPropertyValue || (/^[0-9.]{1,15}$/).test(values.wozPropertyValue)) ? '' : 'Invalid WOZ property value'
        temp.floorAreaSqMeters = (!values.floorAreaSqMeters || (/^[0-9.]{1,15}$/).test(values.floorAreaSqMeters)) ? '' : 'Invalid floor area value'
        setErrors({ ...temp })

        return (Object.values(temp).every(x => x === "")) ? true : false;
    }

    const _saveLead = async () => {
        userAlert.current.clearAll()
        if (validateLead()) {
            let response = await saveLead(
                values
            );
            if (!response.error) {
                //clearAddLeadForm()
                setLeadId(response.id)
                setValues(
                    { ...values, id: response.id }
                )
                userAlert.current.setInfo('Lead saved successfully.')
            }
            else userAlert.current.setError(`Saving lead failed.`)
        }
    }

    const clearDerivedAddressData = () => {
        values.streetHouseNumberSuffix = ''
        values.postalCodeCity = ''
        values.province = ''
        values.provinceCode = ''
        values.city = ''
    }

    const retrieveAddress = async () => {
        userAlert.current.clearAll();
        clearDerivedAddressData();
        let temp = {};
        temp.postalCode = (values.postalCode === '') ? 'Required' : ''
        if (!temp.postalCode) temp.postalCode = (values.postalCode !== '' && !(/^[0-9]{4}[a-zA-Z]{2}$/).test(values.postalCode)) ? 'Invalid postal code' : '';
        temp.houseNumber = (values.houseNumber === '') ? 'Required' : ''
        if (!temp.houseNumber) temp.houseNumber = (values.houseNumber !== '' && !(/^[0-9]{1,6}$/).test(values.houseNumber)) ? 'Invalid house no.' : '';
        setErrors({ ...temp })

        if ((Object.values(temp).every(x => x === ""))) {
            let response = await getAddressByPostalCodeAndHouseNumber(values);

            if (!response.error) {
                userAlert.current.setInfo('Address fetched successfully.');
                setValues({
                    ...values,
                    streetHouseNumberSuffix: `${gPrint(response.street)} ${gPrint(response.houseNumber)} ${gPrint(response.houseNumberSuffix)}`,
                    postalCodeCity: `${gPrint(response.postalCode)} ${gPrint(response.city)}`,
                    province: `${gPrint(response.province)}`,
                    provinceCode: `${gPrint(response.provinceCode)}`,
                    street: `${gPrint(response.street)}`,
                    city: `${gPrint(response.city)}`,
                })
            } else {
                userAlert.current.setError(`Address fetch failed.`)
            }

        }
    }

    const getLeadDetails = async () => {
        let response = await getLeadById(leadId);
        let tempValues = {
            ...response,
            sourceCode: response.source.code,
            postalCode: response.propertyAddress.postalCode,
            houseNumber: response.propertyAddress.houseNumber,
            houseNumberSuffix: response.propertyAddress.houseNumberSuffix,
            province: response.propertyProvince?.name,
            provinceCode: response.propertyProvince?.code,
            streetHouseNumberSuffix: (`${gPrint(response.propertyAddress.street)} ${gPrint(response.propertyAddress.houseNumber)} ${gPrint(response.propertyAddress.houseNumberSuffix)}`).trim(),
            postalCodeCity: (`${gPrint(response.propertyAddress.postalCode)} ${gPrint(response.propertyAddress.city)}`).trim(),
            nextAction: response.nextActionSeries?.action.description,
            priceWLFrom: response.priceWLFrom,
            priceWLTo: response.priceWLTo,
            floorAreaSqMeters: response.floorAreaSquareMeters,
            street: response.propertyAddress.street,
            city: response.propertyAddress.city,
            propertyTypeCode: response.leadPropertyTypes ? response.leadPropertyTypes[0]?.propertyType.code : '',
            salesStartCode: response.salesStart ? response.salesStart.code : '',
        }
        setValues(tempValues);
    }

    useEffect(() => {
        if (leadId) getLeadDetails();
    }, [leadId])

    const callLead = () => {

    }

    // ******************************************************* JSX *******************************************************

    const iconLead = <ContentPasteIcon />
    const iconFile = <AttachFileOutlinedIcon />
    const iconActions = <DirectionsRunOutlinedIcon />

    return (
        <>
            <UserAlert ref={userAlert} />

            <PageHeader title='Add / Modify Lead' icon={iconLead} />
            <form autoComplete='off' noValidate id='frmRegisterVendor'>
                <Box mt={0.0} bgcolor={'background.paper'}>
                    <Grid container>

                        {/* column 1 */}
                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <Box sx={{ height: '36px' }}></Box>
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Search Name' value={values.searchName} name="searchName" error={errors.searchName} required={true} />
                                </Grid>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={10} sx={styles.gridItemPadding}>
                                        <FormInput onChange={handleInputChanage} label='Phone Number' name="phoneNumber" value={values.phoneNumber} error={errors.phoneNumber} />
                                    </Grid>
                                    <Grid item xs={2} sx={styles.gridItemPadding}>
                                        <a href={`tel:${values.phoneNumber}`}>
                                            <IconButton sx={{ float: 'right' }} disabled={values.phoneNumber ? false : true} >
                                                <PhoneForwardedOutlinedIcon color="success" />
                                            </IconButton>
                                        </a>
                                    </Grid>
                                </Grid>

                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Email' name="email" value={values.email} error={errors.email} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <Grid container >
                                        <Grid item flexGrow={1} xs={8} >
                                            <Box display={'flex'}>
                                                <FormSelect
                                                    name="salesStartCode"
                                                    label="Sales Start"
                                                    value={values.salesStartCode || ''}
                                                    onChange={handleInputChanage}
                                                    items={salesStarts}
                                                    error={errors.salesStartCode}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item flexGrow={1} xs={4} pl={0.5}>
                                            <FormInput onChange={handleInputChanage} label='Desired Price' name="priceDesired" value={values.priceDesired} error={errors.priceDesired} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <Grid container >
                                        <Grid item flexGrow={1} xs={8} >
                                            <Box display={'flex'}>
                                                <FormSelect
                                                    name="propertyTypeCode"
                                                    label="Property Type"
                                                    value={values.propertyTypeCode || ''}
                                                    onChange={handleInputChanage}
                                                    items={propertyTypes}
                                                    error={errors.propertyTypeCode}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item flexGrow={1} xs={4} pl={0.5}>
                                            <Box display={'flex'}>
                                                <FormSelect
                                                    name="isOwnProperty"
                                                    label="Own Property ?"
                                                    value={values.isOwnProperty}
                                                    onChange={handleInputChanage}
                                                    items={isOwnPropertyItems}
                                                    error={errors.isOwnProperty}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} name="sellerRemark" label="Seller Remark" multiline={true} rows={3} maxRows={4} value={values.sellerRemark} error={errors.sellerRemark} />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* column 2 */}
                        <Grid item xs={12} md={4}>
                            <Grid container px={0.7}>
                                <Grid container flexWrap={'nowrap'} alignItems={'center'}>
                                    <Box width={'80%'} display={'flex'}>
                                        <Grid item sx={styles.gridItemPadding}>
                                            <FormInput onChange={handleInputChanage} label='Postcode' value={values.postalCode} name="postalCode" error={errors.postalCode} />
                                        </Grid>
                                        <Grid item sx={styles.gridItemPadding} ml={0.3}>
                                            <FormInput onChange={handleInputChanage} label='House Number' value={values.houseNumber} name="houseNumber" error={errors.houseNumber} />
                                        </Grid>
                                        <Grid item sx={styles.gridItemPadding} ml={0.3}>
                                            <FormInput onChange={handleInputChanage} label='Suffix' value={values.houseNumberSuffix} name="houseNumberSuffix" error={errors.houseNumberSuffix} />
                                        </Grid>
                                    </Box>
                                    <Box width={'20%'} display={'flex'}>
                                        <Grid item sx={styles.gridItemPadding} flexGrow={1}>
                                            <Button size='small' type='button' variant="outlined" sx={{ float: 'right' }} onClick={retrieveAddress}>CHECK</Button>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Street/No./Suffix' value={values.streetHouseNumberSuffix} name='streetHouseNumberSuffix' disabled={true} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Postcode/City' value={values.postalCodeCity} name='postalCodeCity' disabled={true} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} label='Province' value={values.province} name='province' disabled={true} />
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <Box display={'flex'}>
                                        <FormInput onChange={handleInputChanage} label='WOZ (property value)' name="wozPropertyValue" value={values.wozPropertyValue} error={errors.wozPropertyValue} />
                                    </Box>
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <Box display={'flex'}>
                                        <FormInput onChange={handleInputChanage} label='m2 BAG' name="floorAreaSqMeters" value={values.floorAreaSqMeters} error={errors.floorAreaSqMeters} />
                                    </Box>
                                </Grid>
                                <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                    <FormInput onChange={handleInputChanage} name="rdVastgoedRemark" label="RD Vastgoed Remark" multiline={true} rows={3} maxRows={4} value={values.rdVastgoedRemark} error={errors.rdVastgoedRemark} />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* column 3 */}
                        <Grid item xs={12} md={4} pr={1}>
                            <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                <Box sx={{ height: '36px' }}></Box>
                            </Grid>
                            <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                <FormInput onChange={handleInputChanage} label='Lead Id' name="id" value={values.id} error={errors.id} disabled={true} />
                            </Grid>
                            <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                <FormSelect
                                    name="sourceCode"
                                    label="Source *"
                                    value={values.sourceCode || ''}
                                    onChange={handleInputChanage}
                                    items={sources}
                                    error={errors.sourceCode}
                                />
                            </Grid>
                            <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                <Box display={'flex'}>
                                    <FormInput onChange={handleInputChanage} label='Source Ad Id' name="sourceAdId" value={values.sourceAdId} error={errors.sourceAdId} />
                                </Box>
                            </Grid>
                            <Grid item flexGrow={1} xs={12} sx={styles.gridItemPadding}>
                                <Grid container>
                                    <Grid item xs={6} sx={{ paddingRight: 0.5 }}>
                                        <FormInput onChange={handleInputChanage} label='WL Price Range (Min)' value={values.priceWLFrom} name='priceWLFrom' error={errors.priceWLFrom} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormInput onChange={handleInputChanage} label='WL Price Range (Max)' value={values.priceWLTo} name='priceWLTo' error={errors.priceWLTo} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Box pb={1} mx={1} width={'100%'}>
                            <Button size='small' type='button' variant="outlined" sx={{ float: 'right', marginLeft: 1 }} onClick={clearAddLeadForm}>CLEAR</Button>
                            <Button size='small' type='button' variant="contained" sx={{ float: 'right', marginLeft: 1 }} onClick={_saveLead}>SAVE</Button>
                            {/* <Button size='small' type='button' variant="outlined" sx={{ float: 'right', marginLeft: 1 }} onClick={closeModal}>CLOSE</Button> */}
                        </Box>
                    </Grid>
                </Box>
            </form>

            {/* action series */}
            {leadId &&
                <>
                    <Box mt={3}>
                        <PageHeader title='Lead Actions' icon={iconActions} />
                    </Box>
                    <Box p={1} bgcolor={'background.paper'} >
                        <Grid container>
                            <Grid item flex={1}>
                                <ActionSeries ownerClass={ACTION_CLASS.LEAD} ownerId={leadId} />
                            </Grid>
                        </Grid>
                    </Box>
                </>
            }

            {/* file attachments */}
            {leadId &&
                <>
                    <Box mt={3}>
                        <PageHeader title='Files' icon={iconFile} />
                    </Box>
                    <Box p={1} bgcolor={'background.paper'} >
                        <Grid container>
                            <Grid item flex={1}>
                                <AttachmentFileList ownerClass={ATTACHMENT_CLASS.LEAD} ownerId={leadId} />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ height: 50 }}>&nbsp;</Box>
                </>
            }
        </>
    )

}

export default LeadUpdate