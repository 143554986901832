import React from 'react';
import { Box, Grid, Stack } from '@mui/material';

const PageHeader = (props) => {
    const conditionalPadding = props.rightSideComponent? 0 : 0.8;

    return (
        <Box height={36} sx={{ backgroundColor: 'grey.200' }}>
            <Stack direction="row"
                justifyContent="space-between" >
                <Grid container justifyContent={'space-between'} alignItems={'center'} pt={conditionalPadding}>
                    <Grid item px={1}>
                        <Grid container justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item>{props.icon}</Grid>
                            <Grid item pl={2}>{props.title}</Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        {props.rightSideComponent}
                    </Grid>
                </Grid>
            </Stack>
        </Box>



    )
}

export default PageHeader