import { gGetError } from '../CommonUtils';
import { apiClientPrivate } from './axios';


export async function searchRecipientsLists(recipientListSearchObj) {
    try {
        let response = await apiClientPrivate.post(`/rest/email-recipient-list/auf/search?pn=${recipientListSearchObj.pn}&ipp=${recipientListSearchObj.ipp}`,
            {
                did: recipientListSearchObj.did,
                name: recipientListSearchObj.name,
                fromDate: recipientListSearchObj.fromDate,
                toDate: recipientListSearchObj.toDate
            });
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function saveRecipientList(recipientListObj) {
    try{
        let response = await apiClientPrivate.post(`/rest/email-recipient-list/auf/create`,
            {
                did: recipientListObj.did,
                name: recipientListObj.name,
                type: recipientListObj.type,
                listOfEmails: recipientListObj.listOfEmails
            });
        return response.data;
    } catch (err) {
        return gGetError(err)
    }
}

export async function deleteRecipientList(recipientListDid) {
    let response = await apiClientPrivate.delete(`/rest/email-recipient-list/auf/${recipientListDid}`);
    return response.data;
}