import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getAttachments, uploadAttachment, downloadAttachment, deleteAttachment } from '../services/AttachmentService';
import AttachmentFileListView from './AttachmentFileListView';
import UserAlert from './UserAlert';

const AttachmentFileList = (props) => {
    let { ownerClass, ownerId } = props;
    const userAlert = useRef(null);

    const initialValues = {
        folderPath: '',
        fileName: '',
    }
    const [values, setValues] = useState(initialValues);
    const [attachments, setAttachments] = useState([]);
    const [fileActionCount, setFileActionCount] = useState(0);
    const fileRef = useRef(null)

    const _getAttachments = useCallback(async () => {
        if (!(ownerClass && ownerId)) return false;
        let response = await getAttachments(ownerClass, ownerId);
        setAttachments(response)
    }, [ownerClass, ownerId])

    useEffect(() => {
        _getAttachments();
    }, [fileActionCount, _getAttachments])

    const handleInputChanage = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleFileChange = (e) => {
        if (!e.target.files) {
            return;
        }
        setValues({
            ...values,
            fileName: e.target.files[0]
        })
    };

    const clearForm = () => {
        setValues(initialValues);
    }

    const validate = () => {
        if (!values.fileName) {
            userAlert.current.setWarn('Please select a file again.')
            return false
        }
    }

    const handleUploadClick = () => {
        userAlert.current.clearAll();
        validate()

        let fileReader = new FileReader();
        fileReader.readAsDataURL(values.fileName);
        fileReader.onload = async (e) => {
            let response = await uploadAttachment(ownerClass, ownerId, values.folderPath, values.fileName.name, e.target.result)
            if (!response.error) {
                setFileActionCount(prevCount => ++prevCount)
                clearForm()
                fileRef.current.reset();
            } else {
                userAlert.current.setError('File upload failed.')
            }
        }
    }

    const _downloadAttachment = async (ownerClass, ownerId, attachment) => {
        let response = await downloadAttachment(ownerClass, ownerId, attachment);
        if(!response.error){
            const linkSource = `data:${response.mimeType}, ${response.contentBase64Encoded}`;
            const downloadLink = document.createElement('a');
            document.body.appendChild(downloadLink);

            downloadLink.href = linkSource;
            downloadLink.target = '_self';
            downloadLink.download = `${response.filename}`;
            downloadLink.click();
            document.body.removeChild(downloadLink);
        
        } else {
            userAlert.current.setError('File download failed.')
        }
    }

    const _deleteAttachment = async (ownerClass, ownerId, attachment) => {
        await deleteAttachment(ownerClass, ownerId, attachment);
        setFileActionCount(prev => ++prev)
    }


    return (
        <AttachmentFileListView
            values={values}
            attachments={attachments}
            handleInputChanage={handleInputChanage}
            handleUploadClick={handleUploadClick}
            handleFileChange={handleFileChange}
            downloadAttachment={_downloadAttachment}
            deleteAttachment={_deleteAttachment}
            ownerClass={ownerClass}
            ownerId={ownerId}
            userAlert={<UserAlert ref={userAlert} />}
            fileRef={fileRef}
        />
    );
}

export default AttachmentFileList;
