import React, { useCallback, useEffect, useRef, useState } from 'react';
import ActionSeriesView from './ActionSeriesView';
import { deleteNextAction, getActionsByClassAndCategory, getActionSeriesByLeadId, getActionSeriesByProspectId, isAClosedAction, saveNextAction } from '../services/ActionService'
import { ACTION_CLASS } from '../constants/AppConsts';
import moment from 'moment';
import UserAlert from './UserAlert';


const ActionSeries = (props) => {
    let {ownerId, ownerClass} = props;
    const userAlert = useRef(null);

    const initialValues = {
        ownerId: ownerId,
        ownerClass: ownerClass,
        actionCode: '',
        actionDate: '',
        remarks: '',
    }
    const [values, setValues] = useState(initialValues);
    const [actionSeries, setActionSeries] = useState([]);
    const [actions, setActions] = useState([]);
    const [actionSaveCount, setActionSaveCount] = useState(0);

    const clearForm = () => {
        setValues(initialValues);
    }

    const handleInputChanage = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const getActions = async () => {
        let actionList = await getActionsByClassAndCategory(ownerClass, '');
        setActions(
            actionList.map(action => {
                return {
                    "id": action.code,
                    "name": `${action.code} - ${action.description}`,
                }
            })
        )
    }

    useEffect(() => {
        getActions();
    }, [])

    const getActionSeries = useCallback(async () => {
        let actionResponse =[]
        if (ownerClass === ACTION_CLASS.LEAD) {
            actionResponse = await getActionSeriesByLeadId(ownerId);
        } else {
            actionResponse = await getActionSeriesByProspectId(ownerId);
        }
        setActionSeries(actionResponse);
    }, [ownerId]) 

    useEffect(() => {
        if (ownerId) getActionSeries();
    }, [ownerId, actionSaveCount, getActionSeries])

    const _saveNextAction = async () => {
        userAlert.current.clearAll();

        if (values.actionCode === '') {
            userAlert.current.setWarn('Next action is required.')
            return false;
        }

        let isClosed = await isAClosedAction(ownerClass, values.actionCode);
        
        if (!isClosed) {
            if (values.actionDate === '') {
                userAlert.current.setWarn('Next action date is required.');
                return false;
            }
            if (!(moment(values.actionDate, "YYYY-MM-DD").isSameOrAfter(new Date(), "day"))) {
                userAlert.current.setWarn('Next action date cannot be in the past.');
                return false;
            }
            // setValues({
            //     ...values,
            //     actionDate: moment(values.actionDate, "YYYY-MM-DD").format("DD/MM/YYYY")
            // })
        }

        let response
        if (!isClosed) {
            response = await saveNextAction(
                {
                    ...values,
                    ownerId: ownerId,
                    ownerClass: ownerClass,
                    actionDate: moment(values.actionDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                }
            )
        } else {
            response = await saveNextAction(
                {
                    ...values,
                    ownerId: ownerId,
                    ownerClass: ownerClass,
                }
            )
        }

        if (!response.error) {
            userAlert.current.setInfo('Next action saved successfully.')
            setActionSaveCount((prevCount) => prevCount + 1)
            clearForm()
        }
        else userAlert.current.setError('Next action saving failed.')
    }

    const deleteAction = async (action) => {
        userAlert.current.clearAll();
        await deleteNextAction(action.did)
        setActionSaveCount((prevCount) => prevCount + 1)
    }

    return (
        <ActionSeriesView
            handleInputChanage={handleInputChanage}
            values={values} 
            saveNextAction={_saveNextAction}
            actionSeries={actionSeries}
            actions={actions}
            deleteAction={deleteAction}
            userAlert={<UserAlert ref={userAlert}/>}
        />
    );
}

export default ActionSeries;
