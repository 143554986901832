import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

const FormSelect = (props) => {

    return (
        <FormControl fullWidth size='small' sx={{ backgroundColor: 'white' }}>
            <InputLabel >{props.label}</InputLabel>
            <Select
                color='secondary'
                name={props.name}
                value={props.value}
                label={props.label}
                onChange={props.onChange}
                required={props.required}
                disabled={props.disabled ? props.disabled : false}
                {...(props.error && {error: true})}
            >
                {
                    props.items.map(
                        (item, index) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        )
                    )
                }
            </Select>
            {props.error && <FormHelperText sx={{color: 'error.main'}}>{props.error}</FormHelperText>}
        </FormControl>
    )
}

export default FormSelect