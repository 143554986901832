import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import HomePageButton from '../../components/HomePageButton';
import { Link } from 'react-router-dom';
import { getAllLeadsCount, getColdLeadsCount, getCreateProspectCount, getHotLeadsCount } from '../../services/LeadService';
import { useState } from 'react';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { getAllActiveInvestorsCount, getInvestorsToBeActivatedCount } from '../../services/InvestorService';
import { gIsAdminUser } from '../../CommonUtils';
import { getManageProspectCount } from '../../services/ProspectService';


const Home = () => {
    const [coldLeadsCount, setColdLeadsCount] = useState(0)
    const [hotLeadsCount, setHotLeadsCount] = useState(0)
    const [allLeadsCount, setAllLeadsCount] = useState(0)
    const [createProspectCount, setCreateProspectCount] = useState(0)
    const [investorsToBeActivatedCount, setInvestorsToBeActivatedCount] = useState(0)
    const [allActiveInvestorsCount, setAllActiveInvestorsCount] = useState(0)
    const [manageProspectCount, setManageProspectCount] = useState(0)

    const _getColdLeadsCount = async () => {
        setColdLeadsCount(getSmartCount(await getColdLeadsCount()));
    }
    const _getHotLeadsCount = async () => {
        setHotLeadsCount(getSmartCount(await getHotLeadsCount()));
    }
    const _getAllLeadsCount = async () => {
        setAllLeadsCount(getSmartCount(await getAllLeadsCount()));
    }
    const _getCreateProspectCount = async () => {
        setCreateProspectCount(getSmartCount(await getCreateProspectCount()));
    }
    const _getInvestorsToBeActivatedCount = async () => {
        setInvestorsToBeActivatedCount(getSmartCount(await getInvestorsToBeActivatedCount()));
    } 
    const _getAllActiveInvestorsCount = async () => {
        setAllActiveInvestorsCount(getSmartCount(await getAllActiveInvestorsCount()));
    }
    const _getManageProspectCount = async () => {
        setManageProspectCount(getSmartCount(await getManageProspectCount()));
    }

    const getSmartCount = (count) => {
        if (count > 999999) return `${(count / 1000000).toFixed(1)}M`;
        else if (count > 999) return `${(count / 1000).toFixed(1)}K`;
        else if (count <= 999) return count;
    }

    useEffect(() => {
        _getColdLeadsCount();
        _getHotLeadsCount();
        _getAllLeadsCount();
        _getCreateProspectCount();
        _getInvestorsToBeActivatedCount();
        _getAllActiveInvestorsCount();
        _getManageProspectCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <form autoComplete='off' noValidate>
                <Box mt={0.0} bgcolor={'background.paper'}>
                    <Grid container height={'calc(100vh - 44px - 18px)'} alignItems={'start'} justifyContent={'space-around'} pt={3}>

                        {/* first column */}
                        <Grid item xs={12} md={6} >
                            <Grid container gap={2} justifyContent={'center'} mt={2}>
                                <Grid item xs={12} md={10}>
                                    <center>
                                        <Link to="/lead-search?mode=COLD_LEADS" style={{ textDecoration: 'none' }}>
                                            <HomePageButton title="Manage Cold Leads" subTitle="Manage leads in initial stages" count={coldLeadsCount}
                                                icon={<ContentPasteIcon color='info' sx={{ fontSize: "48px" }} />} />
                                        </Link>
                                    </center>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <center>
                                        <Link to="/investor-search?mode=INVESTORS_TOBE_ACTIVATED" style={{ textDecoration: 'none' }}>
                                            <HomePageButton title="Activate New Investors" subTitle="Complete & activate new investor profiles" count={investorsToBeActivatedCount}
                                                icon={<HowToRegIcon sx={{ fontSize: "48px", color: "secondary.main" }} />} />
                                        </Link>
                                    </center>
                                </Grid>
                                {gIsAdminUser() && <>
                                    <Grid item xs={12} md={10} >
                                        <center>
                                            <Link to="/lead-search?mode=HOT_LEADS" style={{ textDecoration: 'none', color: 'secondary.main' }}>
                                                <HomePageButton title="Manage Hot Leads" subTitle="Match & mail potential investors to leads" count={hotLeadsCount}
                                                    icon={<ContentPasteIcon color='error' sx={{ fontSize: "48px" }} />}
                                                />
                                            </Link>
                                        </center>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <center>
                                            <Link to="/prospect-search?mode=MANAGE_PROSPECT" style={{ textDecoration: 'none' }}>
                                                <HomePageButton title="Manage Prospects" subTitle="Manage actions related to prospects" count={manageProspectCount}
                                                    icon={<AddCircleIcon color='success' sx={{ fontSize: "48px" }} />} />
                                            </Link>
                                        </center>
                                    </Grid>
                                </>}
                            </Grid>
                        </Grid>

                        {/* second column */}
                        <Grid item xs={12} md={6}>
                            <Grid container gap={2} justifyContent={'center'} mt={2}>
                                <Grid item xs={12} md={10}>
                                    <center>
                                        <Link to="/lead-search" style={{ textDecoration: 'none' }}>
                                            <HomePageButton title="Search, modify, add Leads" subTitle="Search, modify or add leads" count=""
                                                icon={<ContentPasteSearchOutlinedIcon sx={{ fontSize: "48px", color: "background.dark" }} />} />
                                        </Link>
                                    </center>
                                </Grid>
                                {gIsAdminUser() && <>
                                    <Grid item xs={12} md={10}>
                                        <center>
                                            <Link to="/investor-search" style={{ textDecoration: 'none' }}>
                                                <HomePageButton title="Search, modify, add Investors" subTitle="Search, modify or add investors" count={allActiveInvestorsCount}
                                                    icon={<PersonSearchIcon sx={{ fontSize: "48px", color: "background.dark" }} />} />
                                            </Link>
                                        </center>
                                    </Grid>
                                    <Grid item xs={12} md={10} >
                                        <center>
                                            <Link to="/prospect-search" style={{ textDecoration: 'none' }}>
                                                <HomePageButton title="Search Prospects" subTitle="Search prospects" count=""
                                                    icon={<ZoomInOutlinedIcon sx={{ fontSize: "48px", color: "background.dark" }} />} />
                                            </Link>
                                        </center>
                                    </Grid>
                                    <Grid item xs={12} md={10} >
                                        <center>
                                            <Link to="/lead-search?mode=CREATE_PROSPECT" style={{ textDecoration: 'none' }}>
                                                <HomePageButton title="Create Prospects" subTitle="Link an interested investor to a lead" count={createProspectCount}
                                                    icon={<AddCircleOutlineIcon sx={{ fontSize: "48px", color: "background.dark" }} />} />
                                            </Link>
                                        </center>
                                    </Grid>
                                </>}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container height={'calc(100vh - 44px - 18px)'} alignItems={'center'} justifyContent={'space-around'}>
                        <Grid item xs={12} md={12}></Grid>
                    </Grid>
                </Box>
            </form>
        </>
    )
}

export default Home